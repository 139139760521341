import React from 'react'
export default function parallax() {
  return (
    <div>
        <div className="image-container col-md-12 img-fluid turnel-image-second">
     
        </div>
      
    </div>
  )
}
