import React from 'react'
import "../Contactpage.css"
import Navbar from "./Navbar"
import { useState } from 'react'
import lady_img from "../images/contact-us-image.webp"
import Footer from "./footer"
import Swal from "sweetalert2"
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function contact_page() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [product, setProduct] = useState('');
  const [message, setMessage] = useState('');
  const [validated, setValidated] = useState(false);

  const mailvalidation =(value)=>{
    if(/^^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)){
      return true
    }
    else{
      return false
    }
  }


  const sendEmail = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      if (email === "" && name === "" && product === "" && contact === "" ) {
        Swal.fire({
          icon: "error",
          // title: 'Invalid Name',
          text: "Please Enter All Details",
        });
        return ;
      } else if (name === "" || /\d/.test(name)) {
        Swal.fire({
          icon: "error",
          // title: 'Invalid Name',
          text: "Please Enter Your Name",
        });
        return ;
      } 
      else if (email === "") {
        Swal.fire({
          icon: "error",
          // title: 'Caution',
          text: "Please  Enter Your Email",
        });
        return ;
      } 
      if (!mailvalidation(email)) {
        Swal.fire({
          icon: "error",
          text: "Invalid Email",
        });
        return ;
      }else if (contact.length < 10 || !/^[9876]\d{9}$/.test(contact)) {
        Swal.fire({
          icon: "error",
          // title: 'Invalid Contact Number',
          text: "Invalid Contact Number",
        });
        return;
      }
      else if (product === "") {
        Swal.fire({
          icon: "error",
          // title: 'Fill The Module',
          text: "Enter  Your Requirement",
        });
        return ;
      } 
      
    }
    try {
      $.ajax({
        url: "https://appj.scaleedge.in/register",
        type: "POST",
        data: {
          email: email,
          name: name,
          contact: contact,
          product: product,
          message: message,
          subject: "Query Form",
        },
        contentType: "application/x-www-form-urlencoded; charset=UTF-8",
        success: function (response) {
          console.log("Email sent successfully");
          Swal.fire("Good job!", "Mail Send Successfully","success");
          console.log(response);
          setEmail("");
          setName("");
          setProduct("");
          setContact("");
          setMessage("");
          setValidated(false);
        },
        error: function (err) {
          console.log(err);
        },
      });
    } catch (error) {
      console.log(error);
    }
    setValidated(true);
  };



  return (
    <>
    <Navbar/>
    <HelmetProvider>
    <Helmet>
  <title>Oneklick Techno Renewable | Contact Us</title>
  <meta name="description" content=" OneKlick provides high quality solar panels, solar modules and best customer service. Get solar modules for home, be free to contact us at OneKlick at any time.
"/>
<meta name="keywords" content=" solar module distributor in india, solar photo-voltaic panel, solar photo-volatic cell, PV panels, SOLAR CELLS,High Capacity solar panels, Heavy Power Solar Modules, Popular Solar Panels, Jinko Solar panels, solex solar panels, Axitec solar panels, novasys solar panels"/>
<link rel="canonical" href="https://oneklick.in/contact" />
<meta name="twitter:title" content="Oneklick Techno Renewable | Contact Us" />
<meta name="twitter:description" content=" OneKlick provides high quality solar panels, solar modules and best customer service. Get solar modules for home, be free to contact us at OneKlick at any time.
" />
<meta property="og:title" content="Oneklick Techno Renewable | Contact Us"/>
<meta property="og:description" content="OneKlick provides high quality solar panels, solar modules and best customer service. Get solar modules for home, be free to contact us at OneKlick at any time.
"/>
<meta property="og:url" content="https://oneklick.in/contact" />
    </Helmet>
    </HelmetProvider>
      <div className="contact_page">
      <div className="row row1">
                <div className="col-md-6 firstCol">
                    <h1 className="aboutHeading">Contact</h1>
                    <div className="button1">
                        <a href="">Home<i class="fa-solid fa-angle-right"></i><span>Contact Us</span></a>
                    </div>
                </div>
                <div className="col-md-6 secondCol">
        
      <img className="image" src={lady_img} alt="oneklick-contact-img"  title='oneklick-contact-img'/>
                </div>
            </div>

        <div className="container container-for-input">
        <p className='contact_page_subtitle'>Get in contact</p>
          <div className="row">
            <div className="col-md-8">
            <div className="row">
              <div className="col-md-8">
              <h3>Feel free to get in touch <br /> contact with us.</h3>
              <p>Join us in making your solar project a reality! Our expert team is ready to collaborate with you from start to finish, guiding you through the entire process. Let's discuss your plans and embark on this exciting journey towards sustainable energy together. Contact us today!</p>
              </div>
              <div className="col-md-4 message-icon">
              <i className='fa fa-comments fa-4x' style={{color:"#525784" , margin:"30px 0px"}} ></i>
              </div>
            </div>
             
              
              <div className="contact_page_form">
                <form>
                  <div className="row">
                    <div className="col-md-10 first-input">
                    <div class="row">
                    <div class="col">
                    <input type="text" class="form-control" placeholder="Name" name='name' value={name} onChange={(e) => setName(e.target.value)} required/>
                    </div>
                    <div class="col">
                      <input type="email" class="form-control" placeholder="Email" name='email' value={email} onChange={(e) => setEmail(e.target.value)} required/>
                    </div>
                  </div>
                    </div>
                    <div className="col-md-10 first-input">
                    <div class="row">
                    <div class="col">
                    <input type="number" class="form-control" placeholder="number" name='contact' value={contact} onChange={(e) => setContact(e.target.value)} />

                    </div>
                    <div class="col">
                      <input type="text" class="form-control" placeholder="Product Requirement" name='product' value={product} onChange={(e) => setProduct(e.target.value)}/>
                    </div>
                  </div>
                    </div>
                    <div className="col-md-12 text-area-form">
                      <textarea name="message" placeholder='message' id="" cols="82" rows="8" value={message} onChange={(e) => setMessage(e.target.value)} ></textarea>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-4 content-content">
              <div className="contact_page_right col-md-10">
                <div className="row contact_right col-md-12" style={{minHeight:'110px'}}>
                  <div className="col-md-2 contact_page_icon">
                    <i className='fa fa-phone fa-2x'></i>
                  </div>
                  <div className="col-md-6" style={{width:'fit-content',margin:'0px 20px'}}>
                    <div className="page_right_content " >
                      <h4 className='call-us'>Call us now!</h4>
                      <a href="tel:+919511568993">+919511568993</a><br />
                      <a href="tel:+919358589005">+919358589005</a>
                    </div>
                  </div>
                </div>
                {/* <div className="row contact_right col-md-12  "  style={{minHeight:'110px'}}>
                  <div className="col-md-2 contact_page_icon ">
                    <i className='fa-solid fa-envelope fa-2x'></i>
                  </div>
                  <div className="col-md-6" style={{width:'fit-content',margin:'0px 20px'}}>
                    <div className="page_right_content">
                      <h4>Email Us</h4>
                      <a href="mailto:sales@oneklick.in">sales@oneklick.in</a><br />
                      <a href="mailto:saurabh@oneklick.in">saurabh@oneklick.in</a>
                    </div>
                  </div>
                </div> */}
                 <div className="row contact_right col-md-12" style={{minHeight:'110px'}}>
                  <div className="col-md-2 contact_page_icon">
                  <i className='fa-solid fa-envelope fa-2x'></i>
                  </div>
                  <div className="col-md-6" style={{width:'fit-content'}}>
                    <div className="page_right_content ">
                    <h4 className='email-us'>Email Us</h4>
                      <a href="mailto:sales@oneklick.in">sales@oneklick.in</a><br />
                      <a href="mailto:saurabh@oneklick.in">saurabh@oneklick.in</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="button-30 btn-btn mt-3" role="button" onClick={sendEmail}>Send Request</button>
          </div>
         
        </div>
      </div>

<Footer/>
    </>
  )
}
