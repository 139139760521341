import React from 'react'
import Nav from "./Navbar"
import Foot from  "./footer"


export default function PageNotFound() {
  return (
      <>
      <Nav/>  
    <div>
    <div class="d-flex align-items-center justify-content-center vh-100 " style={{flexDirection:'column'}}>
        <h1 class="display-1 fw-bold text-dark">Oops!</h1>
        <h1 class="display-1 fw-bold text-dark">404 - Page Not Found</h1>
    </div>
    </div>
    <Foot/>
    </>
  )
}
