import React from "react";
import { useState } from "react";
import  Campain_Header from "./Campain_jinko"
import img1 from "../../images/Axitec-Copy.webp"
import img2 from "../../images/Jinko.webp"
import img3 from "../../images/Nova.webp"
import img4 from "../../images/solex-1.webp"
import './campaignjinko.css?v=1.1'
import Navbar from "../Navbar"
import Footer from "../footer"
import Swal from "sweetalert2"
import Faq from "../Faq";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Header(props) {
  const faqdeatil = [
    {
      question:" Q1. How to buy solar panels online for your home at the best price in India ?",
      answer :"OneClick Techno Renewable is the leading solar panel supplier in India. OneClick has a experienced and dedicated team that can effectively and efficiently meet your solar panel requirements at the best prices."
    },
    {
      question:"Q2. What is the efficiency of Solex solar panels ?",
      answer :"The efficiency of Solex Solar panels can vary depending on the specific model and technology. Generally, they offer competitive efficiency levels, with some models designed for higher performanc."
    },
    {
      question:"Q3. What types of solar panels does Solex Solar offer ?",
      answer :"Solex Solar offers a wide range of solar panels, including polycrystalline, monocrystalline, bifacial, half-cut cell, and Passivated Emitter Rear Cell."
    },
    {
      question:"Q4. How long do Solex Solar panels last ?",
      answer :"Solex Solar panels are designed to last for 25 years or more. They typically come with performance warranties ensuring a certain level of energy production over a specified period."
    },
    {
      question:"Q5. What is the warranty on Solex Solar modules ?",
      answer :"Yes, Solex Solar panels typically come with warranties that cover performance, workmanship, and durability. The specific warranty terms may vary depending on the solar panel models."
    },
    {
      question:"Q6. Are Solex solar panels good for rooftops ?",
      answer :"Yes, Solex Solar modules can be used for rooftop installations in residential and commercial. it's designed to be versatile and suitable for all conditions."
    },
  
  ]

    const values =[
        {
          image1:img1,
          alt:'axitec-logo',
          title : 'axitec-logo'
        },
        {
          image1:img2,
          alt:'jinko-logo',
          title : 'jinko-logo'
        },
        {
          image1:img3,
          alt:'nova-logo',
          title : 'nova-logo'
        },
        {
          image1:img4,
          alt:'solex-logo',
          title : 'solex-logo'
        },
        {
          image1:img1,
          alt:'axitec-logo',
          title : 'axitec-logo'
        },
        {
          image1:img2,
          alt:'jinko-logo',
          title : 'jinko-logo'
        },
        {
          image1:img3,
          alt:'nova-logo',
          title : 'nova-logo'
        },
        {
          image1:img4,
          alt:'solex-logo',
          title : 'solex-logo'
        }
        
    
      ]




      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [contact, setContact] = useState('');
      const [requirment, setRequirment] = useState('');
      const [validation , setvalidation] = useState(false)
    
      const sendEmail = async (e) => {
        e.preventDefault();
    
        const form = e.currentTarget;
        if(form.checkValidity() === false){
          e.stopPropagation();
        }else {
              if (name === '' && email === '' && contact === '' &&  requirment == '')  {
            Swal.fire({
              icon: 'error',
              title: 'Invalid Name',
              text: 'Please Fill The All Details',
            });
            return;
          }
    
          else if 
          (name === '') {
            Swal.fire({
              icon: 'error',
              // title: 'Invalid Name',
              text: 'Please Enter Your Name',
            });
            return;
          }
          else if
            (email === '') {
              Swal.fire({
                icon: 'error',
                // title: 'Fill The Ema',
                text: 'Please Enter Your Email',
              });
              return;
            }
          else if (contact.length < 10) {
            Swal.fire({
              icon: 'error',
              // title: 'Invalid Contact Number',
              text: 'Please enter a valid contact number with at least 10 digits',
            });
            return;
          }
          else if 
                  (requirment === '') {
            Swal.fire({
              icon: 'error',
              title: 'Fill The Module',
              text: 'Please Enter Your Requirement',
            });
            return;
          }
        }
        try {
          $.ajax({
            url: "https://appj.scaleedge.in/register",
            type: 'POST',
            data: {subject:'Enquiry For Jinko Solar',product:'', name : name,email: email, contact : contact,requirment : requirment, message:''},
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            success: function (response) {
                console.log('Email sent successfully');
                Swal.fire(
                  'Good job!',
                  'Mail Send Successfully',
                  'success'
                );
                console.log(response);
                setName('');
                setEmail('');
                setContact('');
                setRequirment('');
                setvalidation(false)
               
            },
            error: function (err) {
              console.log(err);
            }
          });
        }
        catch(error){
          console.log(error);
        }
        setvalidation(true);
      }
    

  return (
    <>
    <Navbar/>
    <HelmetProvider>
    <Helmet>
  <title>Solex Solar Panel Distributor in India | Buy Solex Modules</title>
  <meta name="description" content=" Solex solar modules in india. OneKlick solar distributor provides solex solar module price list, solar panels EPC services, solex solar panels with best offers.
"/>
<meta name="keywords" content=" Solex solar panel distributor, Solex solar Module distributor In India, Solex solar panels Cost In India, Solex Solar panel supplier In India, Solex Solar energy distributor In India, Solex Renewable energy distributor, Solex Solar panel wholesale, Solex Solar panel price in india, Buy Solex Solar panel online, Best Solex Solar panel supplier near me, solex solar EPC services
"/>
<link rel="canonical" href="https://oneklick.in/solex-solar" />
<meta name="twitter:title" content="Solex Solar Panel Distributor in India | Buy Solex Modules" />
<meta name="twitter:description" content=" Solex solar modules in india. OneKlick solar distributor provides solex solar module price list, solar panels EPC services, solex solar panels with best offers.
" />
<meta property="og:title" content="Solex Solar Panel Distributor in India | Buy Solex Modules"/>
<meta property="og:description" content=" Solex solar modules in india. OneKlick solar distributor provides solex solar module price list, solar panels EPC services, solex solar panels with best offers.
"/>
<meta property="og:url" content="https://oneklick.in/solex-solar" />
    </Helmet>
    </HelmetProvider>
      <div className="col-md-12 header-container">
        <div className="ps-main-container col-md-12 img-fluid ">
        <div className="title-contact-fix">
          <div className="titles col-md-6">
            <div className="title-header" id="title-head">
              <h1>Solex</h1>
              <p className="top-para">
              Reliable Solar Energy
              </p>
             
            </div>
          </div>
          <div className="col-md-4  contact-from-container">
         <div className="contact col-md-12 d-flex justify-content-end align-items-center min-vh-100">
        <div className="second-container"></div>
        <form className="form-data">
        <p className="text-center h2-content fs-2">Contact Form</p>
          <div class="form-row">
            
            <div class="form-group col-md-12">
              <label htmlFor="inputEmail4">Name</label>
              <input
                type="text"
                name="name"
                class="form-control"
                id="inputEmail4"
                placeholder="Enter The name"
                value={name}
                onChange={(e)=>setName(e.target.value)}
                required
              />
            </div>

            <div class="form-group col-md-12">
              <label htmlFor="inputEmail4">Email</label>
              <input
                name="email"
                type="email"
                class="form-control"
                id="inputEmail4"
                placeholder="Enter The Email"
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
                required
              />
            </div>

            <div class="form-group col-md-12">
              <label htmlFor="inputPassword4">number</label>
              <input
                type="len"
                name="contact"
                class="form-control"
                id="inputPassword4"
                placeholder="Enter The Number"
                value={contact}
                onChange={(e)=>setContact(e.target.value)}
                required
              />
            </div>
          </div>
          <div class="form-group">
            <label htmlFor="inputAddress">Product Requirement</label>
            <input
              type="text"
              class="form-control"
              name="requirment"
              id="inputAddress"
              placeholder="Enter The Requirment"
              value={requirment}
              onChange={(e)=>setRequirment(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary long-btn mt-3" onClick={sendEmail}>
            Submit
          </button>
        </form>
      </div>

          </div>
          </div>
          


          {/* <div className="bubbles">
            <span style={{ "--i": 11 }}></span>
            <span style={{ "--i": 28 }}></span>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 10 }}></span>
            <span style={{ "--i": 16 }}></span>
            <span style={{ "--i": 14 }}></span>
            <span style={{ "--i": 24 }}></span>
            <span style={{ "--i": 18 }}></span>
            <span style={{ "--i": 19 }}></span>
            <span style={{ "--i": 78 }}></span>
            <span style={{ "--i": 68 }}></span>
            <span style={{ "--i": 22 }}></span>
            <span style={{ "--i": 61 }}></span>
            <span style={{ "--i": 11 }}></span>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 25 }}></span>
            <span style={{ "--i": 92 }}></span>
            <span style={{ "--i": 97 }}></span>
            <span style={{ "--i": 32 }}></span>
            <span style={{ "--i": 83 }}></span>
            <span style={{ "--i": 31 }}></span>
            <span style={{ "--i": 20 }}></span>
            <span style={{ "--i": 11 }}></span>
            <span style={{ "--i": 22 }}></span>
            <span style={{ "--i": 20 }}></span>
            <span style={{ "--i": 40 }}></span>
            <span style={{ "--i": 1 }}></span>
            <span style={{ "--i": 58 }}></span>
            <span style={{ "--i": 43 }}></span>
            <span style={{ "--i": 38 }}></span>
            <span style={{ "--i": 82 }}></span>
            <span style={{ "--i": 96 }}></span>
            <span style={{ "--i": 87 }}></span>
            <span style={{ "--i": 92 }}></span>
            <span style={{ "--i": 99 }}></span>     
          </div>
          <div className="bubble">
            <span style={{ "--i": 11 }}></span>
            <span style={{ "--i": 28 }}></span>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 10 }}></span>
            <span style={{ "--i": 16 }}></span>
            <span style={{ "--i": 14 }}></span>
            <span style={{ "--i": 24 }}></span>
            <span style={{ "--i": 18 }}></span>
            <span style={{ "--i": 19 }}></span>
            <span style={{ "--i": 78 }}></span>
            <span style={{ "--i": 68 }}></span>
            <span style={{ "--i": 22 }}></span>
            <span style={{ "--i": 61 }}></span>
            <span style={{ "--i": 11 }}></span>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 25 }}></span>
            <span style={{ "--i": 92 }}></span>
            <span style={{ "--i": 97 }}></span>
            <span style={{ "--i": 32 }}></span>
            <span style={{ "--i": 83 }}></span>
            <span style={{ "--i": 31 }}></span>
            <span style={{ "--i": 20 }}></span>
            <span style={{ "--i": 11 }}></span>
            <span style={{ "--i": 22 }}></span>
            <span style={{ "--i": 20 }}></span>
            <span style={{ "--i": 40 }}></span>
            <span style={{ "--i": 1 }}></span>
            <span style={{ "--i": 58 }}></span>
            <span style={{ "--i": 43 }}></span>
            <span style={{ "--i": 38 }}></span>
            <span style={{ "--i": 82 }}></span>
            <span style={{ "--i": 96 }}></span>
            <span style={{ "--i": 87 }}></span>
            <span style={{ "--i": 92 }}></span>
            <span style={{ "--i": 99 }}></span>     
          </div> */}

          {/* second section */}

          <div className="second-containers col-md-12 px-0" style={{ width: '100%' }}>
              <marquee className="marquee-tag"  scrollamount="30" scrolldelay="61" truespeed='true' >
                <div className="slider-value">
               {values.map((items,index)=>(
                    <img src={items.image1} key={index} alt={items.alt} title={items.title} width={'200px'} height={'200px'} className="img-fluid"/>
                ))}
                </div>
              </marquee>
            </div>
            

          </div>
      </div>
      <Campain_Header/>
      <Faq detail={faqdeatil}/>
      <Footer/>
</>)
};