import React from "react";
import womenimg from "../../images/womenimg.webp";
import solproductone from "../../images/1-_250kw_central_standalone.webp";
import solproductsecond from "../../images/3-utility_3.webp";
import solarproductthird from "../../images/7-UTILITY_3-10.webp";
import solarproductfour from "../../images/8-UTILITY60KW.webp";
import PlanetParallax from "./solplanetparallax";
import solarproductfive from "../../images/9-AISWEI.webp";
import Cardsection from "./solplanetcard";
import shedsol from "../../images/Solplanet ShadeSol.webp";
import { Link } from "react-router-dom";
import Faqsecond from "../usebleaccordin";
import visondot from "../../images/background Wave.webp"
import inverterimg from "../../images/invertervision.webp"
const visionsetion = () => {
  const points = [
    {
      question: "1. Solplanet inverters Efficiency",
      answer:
        "These High efficiency is important for solar inverters to minimize energy loss during the conversion process.",
    },
    {
      question: "2. Grid integration",
      answer:
        "Grid-tied inverters synchronize with the utility grid, allowing you to send excess energy back to the grid and potentially earn credits or incentives through net metering programs.",
    },
    {
      question: "3. Remote Control and Diagnostics",
      answer:
        "Solplanet inverter efficiency is an important factor to consider when evaluating and designing a solar power system. Inverter efficiency is usually expressed as a percentage and shows how effectively the inverter performs its primary function.",
    },
    {
      question: "4. Safety Features",
      answer:
        "Inverters include safety features like anti-islanding protection (to prevent sending power to the grid during outages) and ground fault detection to ensure safe and reliable operation.",
    },
    {
      question: "5. Battery Compatibility",
      answer:
        "The inverter is designed to work seamlessly with energy storage systems (batteries). This feature enables you to store excess solar energy for use during periods of low sunshine or power cuts.",
    },
    {
      question: "6. Safety Features",
      answer:
        "Inverters include safety features like anti-islanding protection (to prevent sending power to the grid during outages) and ground fault detection to ensure safe and reliable operation.",
    },
  
  ];
  return (
    <div>
      <div className="vision-container col-md-12 col-12">
    <img src={visondot} className="img-fluid vision-dott" alt="" />
        <div className="row justify-content-center col-md-12 ">
          <div className="left-vision-container col-md-12 col-sm-5 col-xs-12 col-lg-7 p-0 d-flex justify-content-between flex-column">
            <div className="vision-align">
              <p className="fs-3 fw-bold pl-3" style={{zIndex:4,position:'relative',paddingLeft:'20px'}}>The Upsides of Utilizing SolPlanet Inverters</p>
              {/* <div className="vistion-title mb-5 ">
                <h3 className="text-start">Our Vision</h3>
                <p className="mt-1">
                  At Solplanet, we are driven by a simple idea: solar for
                  everybody. We strive to create the best possible experience
                  for distributors, installers and end users. That’s why our
                  products are easy-to-install, reliable and user friendly.
                  Solplanet photovoltaic inverters are manufactured in
                  compliance with international high-quality standards. Our
                  annual production capacity exceeds 10 GW. So, chances are we
                  can meet your demand
                </p>
              </div>
              <div className="vistion-title ">
                <h3 className="text-start">Our Mission</h3>
                <p className="mt-1">
                  Our team of leaders and professionals have extensive
                  background from market leading multi-nationals, largely from
                  Tier-1 international companies such as Siemens Wind, SMA
                  Solar, Huawei and Eaton etc. We are experts in international
                  teamwork. When you partner with us, you get the best of two
                  worlds: Solid process know-how coupled with the agility of a
                  new generation
                </p>
              </div> */}
                <Faqsecond detail={points}/>
            </div>
          </div>
          <div className="right-vision-container col-md-7 col-sm-12 col-xs-12 " >
            <img src={womenimg} className="img-fluid" width={"100%"} alt="" />
          </div>
        </div>
      </div>
      <div className="col-md-12 header-section mb-0 planet-div d-flex justify-content-center align-items-center">
              <h2
                data-aos-duration="800"
                data-aos="fade-right"
                data-aos-once="true"
                className='px-4'
              >
              Accurate Data, Clear Insights, Smart Decisions
              </h2> 
              
            </div>
      <Cardsection /> 
      {/* Points section */}

      {/* <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
      <div className="col-md-10">
      <div className="row">
      <div className="left-vison-sec col-md-6 col-12">
      <Faqsecond detail={points}/>
      </div>
      <div className="right-vison-sec col-md-6 d-flex justify-content-center align-items-center  m-auto">
        <img src={inverterimg} alt="" className="img-fluid " style={{maxHeight:'450px',height:'450px'}} />
      </div>
      </div>
      </div>
      </div> */}
      {/* <div className="child-container">
        <div className="col-md-8 header-section">
          <h2
            data-aos-duration="800"
            data-aos="fade-right"
            data-aos-once="true"
            style={{ marginTop: "60px" }}
            className="px-1 hide-tit"
          >
            Solplanet Inverters Features
          </h2>
          <p data-aos-duration="800" data-aos="fade-left" data-aos-once="true">
            Solplanet Solar inverters come with various features to enhance
            their performance and functionality.
            <li className="mt-3">
              <b>Solplanet inverters Efficiency:</b> These{" "}
             
              High efficiency is important for solar inverters to minimize
              energy loss during the conversion process.
            </li>
            <li>
              <b>Grid integration:</b>
              Grid-tied inverters synchronize with the utility grid, allowing
              you to send excess energy back to the grid and potentially earn
              credits or incentives through net metering programs.
            </li>
            <li>
              <b>Battery Compatibility: </b>
              The inverter is designed to work seamlessly with energy storage
              systems (batteries). This feature enables you to store excess
              solar energy for use during periods of low sunshine or power cuts.
            </li>
            <li>
              <b>Design: </b>Inverters are smaller, lighter, and more efficient
              than traditional transformer-based models. They are often liked
              for their low energy loss and compact size.
            </li>
            <li>
              <b>Safety Features: </b>Inverters include safety features like
              anti-islanding protection (to prevent sending power to the grid
              during outages) and ground fault detection to ensure safe and
              reliable operation.
            </li>
          </p>
        </div>
      </div>
      <div className="child-container">
        <div className="col-md-8 header-section m-0">
          <h2
            data-aos-duration="800"
            data-aos="fade-right"
            data-aos-once="true"
            style={{ marginTop: "20px" }}
            className="px-1 hide-tit"
          >
            Benefits of Solplanet Inverters
          </h2>
          <p data-aos-duration="800" data-aos="fade-left" data-aos-once="true">
          Solplanet Solar inverters offer several key benefits that contribute to the
            overall efficiency and effectiveness of solar energy utilization.
            <li className="mt-3">
              <b>Improve efficiency:</b> These{" "}
              
              Solplanet inverters maximize the energy output of solar panels by
              operating at or near their peak efficiency.
            </li>
            <li>
              <b>Environmental Benefits:</b>
              Solplanet inverters contribute to the reduction of greenhouse gas
              emissions and environmental pollution.
            </li>
            <li>
              <b>Longevity and Durability: </b>
              Solplanet inverters are designed to operate quietly, making them
              suitable for residential installations where noise may be a
              concern.
            </li>
            <li>
              <b>Remote Control and Diagnostics: </b>Solplanet inverters offer remote
              control and diagnostic capabilities, allowing you to troubleshoot
              issues and adjust settings without physical access to the
              inverter.
            </li>
          </p>
        </div>
      </div> */}
      <PlanetParallax />
      <div className="child-container m-0 bg-light ">
        <div className="col-md-12 col-lg-9 col-sm-12 header-section p-4  ">
          <h2
            data-aos-duration="800"
            data-aos="fade-right"
            data-aos-once="true"

            className="px-1 hide-tit bg-dark text-light"
          >
            16 Years Know-how 
in PV Research & Development

          </h2>
          </div>
      </div>
          <div className="timeline col-md-12 col-12 bg-light">
            <div className="col-md-12 col-12 container  d-flex justify-content-center flex-column align-items-center px-4">
              <div className="col col-md-10 subtitle-con">
                <div className="col-md-12 col-lg-6  col-sm-6 first-timeline">
                  <div className="stateline"></div>

                  <div className="timeline-round">
                    <p className="year">2010</p>
                  </div>
                  <div className="line-one"></div>
                  <div className="line-two"></div>
                  <div className="first-timeline-cont d-flex justify-content-evenly align-items-center" >
                    <img
                      src={solproductone}
                      className="img-fluid"
                      width={"60px"}
                      alt=""
                    />
                    <div>
                      <p className="m-0 text-light">Central Standalone</p>
                      <p className="m-0 text-light">500KW</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 col-sm-6  second-timeline" style={{top:'-15px'}}>
                  <div className="timeline-round-second" style={{background:'rgb(39 57 202)'}}>
                    <p className="year-second">2011</p>
                  </div>
                  <div className="second-line-one" style={{background:'rgb(39 57 202)'}}></div>
                  <div className="second-line-two" style={{background:'rgb(39 57 202)'}}></div>
                  <div className="second-timeline-cont  d-flex justify-content-evenly align-items-center" style={{background:'rgb(39 57 202)'}}>
                    <img
                      src={solproductsecond}
                      className="img-fluid"
                      width={"60px"}
                      alt=""
                    />
                    <div>
                      <p className="m-0 text-light">
                        Utility three phase string
                      </p>
                      <p className="m-0 text-light">17kw</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-md-10 subtitle-con">
                <div className="col-md-12 col-lg-6 col-sm-6 first-timeline" style={{top:'-30px'}}>
                  <div className="timeline-round bg-warning">
                    <p className="year">2012</p>
                  </div>
                  <div className="line-one bg-warning"></div>
                  <div className="line-two bg-warning"></div>
                  <div className="first-timeline-cont d-flex justify-content-evenly align-items-center bg-warning">
                    <img
                      src={solproductsecond}
                      className="img-fluid"
                      width={"60px"}
                      alt=""
                    />
                    <div>
                      <p className="m-0 text-light">
                        Utility three phase string
                      </p>
                      <p className="m-0 text-light">3-10kw</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 col-sm-6  second-timeline" style={{top:'-45px'}}>
                  <div className="timeline-round-second bg-info">
                    <p className="year-second ">2016</p>
                  </div>
                  <div className="second-line-one bg-info"></div>
                  <div className="second-line-two bg-info"></div>
                  <div className="second-timeline-cont d-flex justify-content-evenly align-items-center bg-info">
                    <img
                      src={solarproductthird}
                      className="img-fluid"
                      width={"60px"}
                      alt=""
                    />
                    <div>
                      <p className="m-0 text-light">
                        Utility three phase string
                      </p>
                      <p className="m-0 text-light">3-10KW SMA</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-md-10 subtitle-con">
                <div className="col-md-12 col-lg-6 col-sm-6 first-timeline" style={{top:'-60px'}}>
                  <div className="timeline-round" style={{background:'#6D41A1'}}>
                    <p className="year">2017</p>
                  </div>
                  <div className="line-one" style={{background:'#6D41A1'}}></div>
                  <div className="line-two" style={{background:'#6D41A1'}}></div>
                  <div className="first-timeline-cont d-flex justify-content-evenly align-items-center" style={{background:'#6D41A1'}}>
                    <img
                      src={solarproductfour}
                      className="img-fluid"
                      width={"60px"}
                      alt=""
                    />
                    <div>
                      <p className="m-0 text-light">
                        Utility three phase string
                      </p>
                      <p className="m-0 text-light">60KW SMA</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 col-sm-6  second-timeline" style={{top:'-75px'}}>
                  <div className="timeline-round-second">
                    <p className="year-second">2019</p>
                  </div>
                  <div className="second-line-one"></div>
                  <div className="second-line-two"></div>
                  <div className="second-timeline-cont d-flex justify-content-evenly align-items-center">
                    <img
                      src={solarproductfive}
                      className="img-fluid"
                      width={"60px"}
                      alt=""
                    />
                    <div>
                      <p className="m-0 text-light">AISWEI</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
      <div className="shedsol bg-light">
        <img src={shedsol} className="img-fluid" alt="" />
      </div>
    </div>
  );
};

export default visionsetion;
