import React from 'react'
import img9 from '../images/jinkosheet1.jpg'
import img10 from "../images/jinkosheet2.jpg"
import img11 from "../images/jinkosheet3.jpg"
import img12 from "../images/jinkosheet4.jpg"
import img13 from "../images/jinkosheet5.jpg"
import img14 from "../images/jinkosheet6.jpg"
import img15 from "../images/axitecsheet1.jpg"
import img16 from "../images/axitecsheet2.jpg"
import img17 from "../images/novasyssheet1.jpg"
import img18 from "../images/novasyssheet2.jpg"
import Navbar from "./Navbar"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Footer from "./footer"
const solardatasheet = () => {
    const jinkosheetdata = [
    {
        // sheets:pdf1,
        imgSrc: img9,
        title: "Tiger Pro – 550 Wp",
        alt: 'jinko-solar-panel-Tiger-Pro',
        titles :'jinko-solar-panel-Tiger-Pro'

    },
    {
    //   sheets:pdf1,
        imgSrc: img10,
        title: "Tiger – 470 Wp",
        alt: 'jinko-solar-panel-Tiger-470',
        titles :'jinko-solar-panel-Tiger-470'
    },
    {
      
        imgSrc: img11,
        title: "Tiger Neo Bifacial – 72 Cell N-type",
        alt: 'jinko-solar-panel-bifacial',
        titles :'jinko-solar-panel-bofacial'
        
    },
    {
        imgSrc: img12,
        title: "Tiger Neo Mono – 78 Cell N-type",
        alt: 'jinko-solar-panel-78',
        titles :'jinko-solar-panel-78'
    },
    {
        imgSrc: img13,
        title: "Tiger Neo Mono – 72 Cell N-type",
        alt: 'jinko-solar-panel-72',
        titles :'jinko-solar-panel-72'
    },
    {
        imgSrc: img14,
        title: "Tiger Neo Bifacial – 78 Cell N-type",
        alt: 'jinko-solar-panel-78',
        titles :'jinko-solar-panel-78'
    }

]

const axitecsheetdata = [
    {
        imgSrc: img15,
        title: "AXIworldpower – 335Wp NON-DCR",
        alt: 'axitec-solar-panel-335wp',
        titles :'axitec-solar-panel-335wp'
        
    },
    {
        imgSrc: img16,
        title: "AXIworldpremium XXL HC – 550Wp",
        alt: 'axitec-solar-panel-550wp',
        titles :'axitec-solar-panel-550wp'
    },
    {
        imgSrc: img15,
        title:  "AXIworldpower – 335Wp DCR",
        alt: 'axitec-solar-panel-335wp-dcr',
        titles :'axitec-solar-panel-335wp-dcr'
    }
]

const novasyssheetdata = [
    {
        imgSrc: img17,
        title: "Vega Series – 335Wp DCR",
        alt: 'nova-solar-panel-335wp-dcr',
        titles :'nova-solar-panel-335wp-dcr'
    },
    {
        imgSrc: img18,
        title: "Alpha Plus Series – 550Wp",
        alt: 'nova-solar-panel-550wp',
        titles :'nova-solar-panel-550wp'
    },
    {
        imgSrc: img17,
        title: "Vega Series – 335Wp NON – DCR ",
        alt: 'nova-solar-panel-335wp-dcr',
        titles :'nova-solar-panel-335wp-dcr'
    }
]

   const handleDownload = (imgSrc) => {
        const link = document.createElement('a');
        link.href = imgSrc;
        link.download = 'image.jpg';
        link.click();
    };

  return (
    <div>
    <Navbar/>
    <HelmetProvider>
      <Helmet>
  <title>Buy Solar Modules at OneKlick Techno Renewable Solar Distributor</title>
  <meta name="description" content=" OneKlick is an authorized solar panel distributor in India and you can easily buy solar modules. Get solar modules and solar EPC services for homes, offices and commercial spaces.
"/>
<meta name="keywords" content=" Jinko solar, novasys solar, Buy Solar Panel, solar module distributors, Solar Panel, Solar Panel Price, solar panels distributor, Axitec Solar, Bifacial Solar Panel, Jinko Bifacial 530, Jinko Bifacial 545, Jinko N type Module,novasys solar panel, Solar Module Distributor in india, Solar Module Price, Solex
"/>

<link rel="canonical" href="https://oneklick.in/solar-product" />
<meta name="twitter:title" content="Buy Solar Modules at OneKlick Techno Renewable Solar Distributor" />
<meta name="twitter:description" content=" OneKlick is an authorized solar panel distributor in India and you can easily buy solar modules. Get solar modules and solar EPC services for homes, offices and commercial spaces.
" />
<meta property="og:title" content="Buy Solar Modules at OneKlick Techno Renewable Solar Distributor"/>
<meta property="og:description" content=" OneKlick is an authorized solar panel distributor in India and you can easily buy solar modules. Get solar modules and solar EPC services for homes, offices and commercial spaces.
"/>
<meta property="og:url" content="https://oneklick.in/solar-product"/>

    </Helmet>
    </HelmetProvider>
    <div className='productsheet'>
        <div className="text-center mt-5">
        <h1 className='fw-5 fs-1 '>Popular Solar Panels In India</h1>
        </div>
                <div className='section-title section-tit flex-column'>
                    <h2>Jinko Solar</h2>
                    <p className='text-dark col-md-9 text-center' style={{fontSize:'16px',fontWeight:500}}>Jinko Solar panels are known for their high conversion efficiency, cost-effective, well-designed, and High-Temperature Resistance. Jinko Solar offers both monocrystalline and polycrystalline solar panels. solar modules have strong frames and tempered glass to protect the solar cells. Jinko Solar invests in research and development, incorporating advanced technologies into their panels to improve performance and reliability. The popular Jinko solar modules are <b style={{cursor:'pointer'}}> <a href="./Jinko-Solar" className='text-dark'> Jinko Bifacial Solar Module </a></b>, N - Type Solar Panel, P-type Jinko Solar, Jinko monofacial 545,<b style={{cursor:'pointer'}}> <a href="./Jinko-Solar" className='text-dark'> Jinko Bifacial 530</a></b>, and Jinko Bifacial 545. You can esily buy Jinko solar panels at the best prices with Oneklick.</p>
                </div>
                <div className='section-content p-0'>
                    <div className='container'>
                        <div className='row sheets'>
                            {jinkosheetdata.map((item, index) => (

                                <div className='col-md-4 sheet-col' key={index}>
                                    <img src={item.imgSrc} alt={item.alt} title={item.titles} ></img>
                                    <h3>{item.title}</h3>
                                    <a  onClick={() => handleDownload(item.imgSrc)}><div className='download-btn'  style={{cursor:'pointer'}}>Download DataSheet</div></a>
                                </div>
                            )
                            )
                            }
                        </div>
                    </div>
                </div>
                <div className='section-title section-tit flex-column'>
                    <h2>AXITEC SOLAR</h2>
                    <p className='text-dark col-md-9 text-center' style={{fontSize:'16px',fontWeight:500}}>AXITEC Solar is a top solar panel manufacturer known for manufacturing high-quality solar panels. AXITEC solar panels are designed for high efficiency, reliable and consistent performance, long durability, strong warranty, and innovative technology. AXITEC solar modules are a reliable and efficient choice for homeowners and businesses seeking sustainable energy solutions. Here are some of the top solar modules and features. You can easily buy solar modules at the best price.
</p>
                </div>
                <div className='section-content'>
                    <div className='container'>
                        <div className='row sheets'>
                            {axitecsheetdata.map((item, index) => (

                                <div className='col-md-4 sheet-col' key={index}>
                                    <img src={item.imgSrc}  alt={item.alt} title={item.titles}></img>
                                    <h3>{item.title}</h3>
                                    <a  onClick={() => handleDownload(item.imgSrc)}><div className='download-btn' style={{cursor:'pointer'}}>Download DataSheet</div></a>
                                </div>
                            )
                            )
                            }
                        </div>
                    </div>
                </div>
                <div className='section-title section-tit flex-column'>
                    <h2>NOVASYS GREEN</h2>
                    <p className='text-dark col-md-9 text-center' style={{fontSize:'16px',fontWeight:500}}>Novasys solar panels deliver cutting-edge solar technology combining innovation and sustainability. Their top features include exceptional energy efficiency, boasting high conversion rates to maximize energy output. Their solar modules are eco-friendly, reducing carbon footprint and promoting a clean environment. Novasys  solar panels come with a reliable warranty, assuring customers of their performance and reliability. solar modules are a smart choice for those seeking sustainable energy solutions with top-notch performance and aesthetics.</p>
                </div>
                <div className='section-content'>
                    <div className='container'>
                        <div className='row sheets'>
                            {novasyssheetdata.map((item, index) => (

                                <div className='col-md-4 sheet-col' key={index}>
                                    <img src={item.imgSrc}  alt={item.alt} title={item.titles}></img>
                                    <h3>{item.title}</h3>
                                    <a  onClick={() => handleDownload(item.imgSrc)}><div className='download-btn'  style={{cursor:'pointer'}}>Download DataSheet</div></a>
                                </div>
                            )
                            )
                            }
                        </div>
                    </div>
                </div>

            </div>



 
            <Footer/>
    </div>
  )
}

export default solardatasheet
