import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Product from "./components/products";
import Contact_page from "./components/contact_page";
import AboutPage from "./components/About";
import WarehousePage from "./components/warehouse";
import Newblog from "./components/blog";
import JinkoSolar from "./components/campain/Jinko-Solar";
import CampaignJinko from "./components/campain/Solex-Solar";
import PageNotFound from "./components/PageNotFound";
import Blogtem from "./components/blog-page"
import Solardatasheet from "./components/solardatasheet";
import Mainsolplanet from "./components/solplanet/Mainsolplanet"
export default function App() {
  // const title = [
  //   {
  //     name: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
  //     para: "Posted on March 20,2022",
  //   },
  //   {
  //     name: "Lorem ipsum dolor sit amet",
  //     para: "Posted on March 20,2023",
  //   },
  //   {
  //     name: "Lorem ipsum dolor sit amet,iasbdfishifhiknaxhikfieh",
  //     para: "Posted on March 20,2023",
  //   },
  // ];
  return (
    <>
     
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/products" element={<Product />}></Route>
          <Route path="/blog" element={<Newblog />}></Route>
          <Route path="/contact" element={<Contact_page />}></Route>
          <Route path="/About" element={<AboutPage />}></Route>
          <Route path="/warehouse" element={<WarehousePage />}></Route>
          <Route path="/Jinko-Solar" element={<JinkoSolar />}></Route>
          <Route path="/Solex-Solar" element={<CampaignJinko />}></Route>
          <Route path="/blog-page/tanmay" element={<Blogtem/>}></Route>
          <Route path="/blog-page/solplanet" element={<Blogtem/>}></Route>
          <Route path="/solar-product" element={<Solardatasheet/>}></Route>
          <Route path="/solplanet" element={<Mainsolplanet/>}></Route>
          <Route path="*" element={<PageNotFound/>}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
