import React, { useState } from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Swal from 'sweetalert2'


export default function Contact() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [product, setProduct] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [validated, setValidated] = useState(false);

  const mailvalidation =(value)=>{
    if(/^^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)){
      return true
    }
    else{
      return false
    }
  }


  const sendEmail = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      if (email === "" && name === "" && product === "" && contact === "" ) {
        Swal.fire({
          icon: "error",
          // title: 'Invalid Name',
          text: "Please Enter All Details",
        });
        return ;
      } else if (name === "" || /\d/.test(name)) {
        Swal.fire({
          icon: "error",
          // title: 'Invalid Name',
          text: "Please Enter Your Name",
        });
        return ;
      } 
      else if (email === "") {
        Swal.fire({
          icon: "error",
          // title: 'Caution',
          text: "Please  Enter Your Email",
        });
        return ;
      } 
      if (!mailvalidation(email)) {
        Swal.fire({
          icon: "error",
          text: "Invalid Email",
        });
        return ;
      }else if (product === "") {
        Swal.fire({
          icon: "error",
          // title: 'Fill The Module',
          text: "Enter  Your Requirement",
        });
        return ;
      } else if (contact.length < 10 || !/^[9876]\d{9}$/.test(contact)) {
        Swal.fire({
          icon: "error",
          // title: 'Invalid Contact Number',
          text: "Invalid Contact Number",
        });
        return;
      }
      
    }
    try {
      $.ajax({
        url: "https://appj.scaleedge.in/register",
        type: "POST",
        data: {
          email: email,
          name: name,
          contact: contact,
          product: product,
          message: message,
          subject: "Query Form",
        },
        contentType: "application/x-www-form-urlencoded; charset=UTF-8",
        success: function (response) {
          console.log("Email sent successfully");
          Swal.fire("Good job!", "Mail Send Successfully","success");
          console.log(response);
          setEmail("");
          setName("");
          setProduct("");
          setContact("");
          setMessage("");
          setValidated(false);
        },
        error: function (err) {
          console.log(err);
        },
      });
    } catch (error) {
      console.log(error);
    }
    setValidated(true);
  };


  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="contact-us">
      <div
        className="section-title section-title-new"
        data-aos="zoom-in"
        data-aos-once="true"
      >
        <div className="introduction">
          <p>Have any Project in Mind?</p>
        </div>
        <div
          className="header-title col-md-6"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <h2>
            <strong> Contact Us</strong>
          </h2>
        </div>
      </div>
      <div
        className="paragraph-written"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          className="row col-md-8"
          style={{ textAlign: "center", padding: "20px" }}
        >
          <p>
            Contact us now to bring your solar project to life! <br /> Our
            expert team will collaborate with you, guiding you from concept to
            execution for a successful outcome. Let's discuss your plans and
            embark on a sustainable energy journey together. Don't wait, get in
            touch today!
          </p>
        </div>
      </div>
      <div className="contact-details">
        <div className="row" style={{ justifyContent: "center" }}>
          <div
            className="col-md-3 contact-address"
            data-aos="flip-left"
            data-aos-once="true"
          >
            <h3>Details</h3>
            <div className="col-md-12 mt-5 lefts-container ">
              <i className="fa-solid fa-envelope fa-3x"></i>
              <div className="">
                <h3 style={{ textAlign: "start" }}>Email</h3>
                <a href="mailto:sales@oneklick.in">sales@oneklick.in</a>
              </div>
            </div>
            <div className="col-md-12 mt-5 lefts-container ">
              <i className="fa-sharp fa-solid fa-square-phone fa-3x"></i>
              <div className="">
                <h3 style={{ textAlign: "start" }}>Phone</h3>
                <a href="tel:+919511568993">+91-9511568993</a>
                <br />
                <a href="tel:+919358589005">+91-9358589005</a>
              </div>
            </div>
          </div>
          <div
            className="col-md-8 address-form-section"
            data-aos="flip-right"
            data-aos-once="true"
          >
            <div className="contact-form">
              <p>
                While we are good with smoke signals, there are simpler ways for
                us to get in touch and answer your questions.
              </p>
              <form>
                <div className="row" style={{ margin: 0 }}>
                  <div className="col-md-12">
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="NAME"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="E-MAIL"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      class="form-control"
                      name="product"
                      value={product}
                      onChange={(e) => setProduct(e.target.value)}
                      placeholder="PRODUCT REQUIRED"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="number"
                      class="form-control"
                      name="contact"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                      placeholder="CONTACT NUMBER"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      class="form-control"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="MESSAGE"
                    />
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="btn text-light "
                      onClick={sendEmail}
                      required
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
