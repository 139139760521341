import React from 'react'
export default function turnel() {
  return (
    <div>
        <div className="image-container col-md-12 img-fluid turnel-image" title="solex-panel" alt="solex-panel">
     
        </div>
      
    </div>
  )
}
