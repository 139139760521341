import  { useEffect } from 'react'
import React from 'react'
import "../Partner.css?v=1.1"
import AOS from "aos";
import "aos/dist/aos.css"
import Footer from "./footer"
const Partner = () => {
  
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
 
  return (
  <>

<div className="background1">
    
<h2 className='partner partner-main' data-aos="zoom-in" data-aos-once="true">Partner with OneKlick</h2>
<p className='partner' data-aos="zoom-in" data-aos-once="true" style={{fontSize:'30px'}}>Select and Get the Right Solar Module</p>
<div className="paragraph-written" style={{display:'flex',justifyContent:"center"}} >
            <div className="row col-md-8" style={{textAlign:"center",padding:'20px'}}>
            <p style={{color:'wheat',letterSpacing:'1.1px', fontSize:'17px'}}>Go solar with OneKlick! <br /> Our experts will guide you to the perfect solar module for your needs. Trust us for a simple and efficient process, ensuring a brighter, sustainable future</p>
            </div>
          </div>

<div style={{paddingBottom:"40px",paddingTop:"25px"}} className="vc_btn3-container wpb_animate_when_almost_visible wpb_bounceInUp bounceInUp vc_btn3-center wpb_start_animation animated">
	<a  className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-modern vc_btn3-color-grey" data-aos="zoom-out" data-aos-once="true" href="./contact" title="">Get in Touch with us</a></div>

</div>
  </>
  )
}

export default Partner
