import React from 'react'

const solplanetparallax = () => {
  return (
    <div>
        <div className="solplanet-parallax my-5 mb-0 ">

        </div>
    </div>
  )
}

export default solplanetparallax
