import React, { useEffect } from 'react'
import img7 from "../images/mapimage.webp"
import AOS from "aos"
import "aos/dist/aos.css"

export default function Warehouse() {

  useEffect(
    ()=>{
      AOS.init({duration:1000})
    }
  )
  return (
    <div>
      <div className='warehouse fade-in'>
        <div className='section-title' data-aos="zoom-in" data-aos-once='true'>
        <div className='introduction '>
        <p>Our Warehouse</p>
        </div>
        <div className="header-title col-md-6" style={{width:'100%', display:'flex',justifyContent:"center"}}>
          <h2> <strong> Efficiency from Warehouses to Your Doorstep:
          Trust Our Safe & Timely Solar Deliveries! </strong></h2>
          </div>
        </div>
        <div className="paragraph-written" style={{display:'flex',justifyContent:"center"}} >
            <div className="row col-md-8" style={{textAlign:"center",padding:'20px'}}>
            <p>Experience efficiency every step of the way, <strong> from our warehouses to your doorstep</strong>, with our trusted and reliable solar module deliveries. We <strong> prioritize safety and timeliness</strong>, ensuring that your solar products are <strong> delivered securely and on schedule</strong>. Count on us to handle the logistics seamlessly, so you can focus on harnessing the power of solar energy with <strong> peace of mind.</strong> Our customer satisfaction reflects everything about us and makes us the best <strong>solar panel distributor in India. </strong></p>
            </div>
          </div>
        <div className='row sort-content' style={{ margin: 0, flexDirection:'row-reverse', justifyContent:'center',marginTop:"75px"}}>
          <div className='col-md-7 warehouse-map'>
            <img src={img7}  data-aos="fade-down-right" data-aos-once='true' alt='oneklick-warehouse' title='oneklick-warehouse'></img>
          </div>
          <div className='col-md-4 our-warehouses' data-aos="zoom-in" data-aos-once='true' style={{display:'flex',alignItems:"start",flexDirection:"column",padding:"60px"}}>
            <h3 className='warehouse-detail'><strong>Empowering Solar Energy Nationwide </strong></h3>
            <p className='warehouse-detail' style={{fontSize:'20px'}}>Unveiling Our Extensive Solar Panel Warehouses in India</p>
            <ul className='warehouse-list'>
              <strong><li><h4>Ludhiana</h4></li></strong>
              <strong><li><h4>Alwar</h4></li></strong>
              <strong><li><h4>Jaipur</h4></li></strong>
              <strong><li><h4>Ahemdabad</h4></li></strong>
              <strong><li><h4>Bhiwandi</h4></li></strong>
              <strong><li><h4>Hyderabad</h4></li></strong>
              <strong><li><h4>Chennai</h4></li></strong>
            </ul>
          </div>
        </div>

      </div>
      
    </div>
  )
}



