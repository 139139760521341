import React from 'react'
// import img9 from '../images/jinkosheet1.jpg'
// import img10 from "../images/jinkosheet2.jpg"
// import img11 from "../images/jinkosheet3.jpg"
// import img12 from "../images/jinkosheet4.jpg"
// import img13 from "../images/jinkosheet5.jpg"
// import img14 from "../images/jinkosheet6.jpg"
// import img15 from "../images/axitecsheet1.jpg"
// import img16 from "../images/axitecsheet2.jpg"
// import img17 from "../images/novasyssheet1.jpg"
// import img18 from "../images/novasyssheet2.jpg"
import tigerbi from  "../images/Tiger Bifacial - Ntype.png"
import tigerbipdf from "../images/TR JKM455-475M-7RL3-TV-A3-EN(IEC 2005).pdf"
import tigerproimg from  "../images/tiger_panel_right - Tiger Bifacial.png"
import tigerpro from "../images/JKM535-555M-72HL4-(V)-F3-EN-For India.pdf"
import tigermono from  "../images/tiger_panel_left - Tiger Monofacial.png"
import tigermonopdf from "../images/JKM535-555M-72HL4-(V)-F3-EN-For India.pdf"
import tigerneo  from "../images/Tiger Bifacial - Ntype.png"
import tigerneopdf  from "../images/JKM565-585N-72HL4-(V)-F2-EN(IEC 2005).PDF"

import tigerneobi  from "../images/tiger2 - 465 Ptype Bifacial.png"
import tigerneobipdf  from "../images/JKM565-585N-72HL4-BDV-F4-EN (IEC 2005).pdf"

import tigerneobi2  from "../images/tiger2 - 465 Ptype Bifacial.png"
import tigerneobipdf2  from "../images/JKM600-620N-78HL4-BDV-F4-EN (IEC 2005).pdf"
import img1 from "../images/aswlt-g3.png"
import pdf1 from "../images/aswlt-g3.png"
import img2 from "../images/three-phase-hybrid.png"
import pdf2 from "../images/three-phase-hybrid.pdf"
import img3 from "../images/single-phase-hybrid.png"
import pdf3 from "../images/single-phase-hybrid.pdf"
import img4 from "../images/sqlevpower.png"
import pdf4 from "../images/sqlevpower.pdf"
import img5 from "../images/asw3000-s.png"
import pdf5 from "../images/asw3000-s.pdf"
import img6 from "../images/aswlt-g2.png"
import pdf6 from "../images/aswlt-g2.pdf"

import Footer from "../components/footer"
import jinko from "../components/campain/Jinko-Solar"
import jinkopdt from "../../src/images/tiger_panel_left - Tiger Monofacial.png";

// const jinkosheetdata = [
//     {
//         sheets:pdf1,
//         imgSrc: img9,
//         title: "Tiger Pro – 550 Wp",
//         alt: 'jinko-solar-panel-Tiger-Pro',
//         titles :'jinko-solar-panel-Tiger-Pro'

//     },
//     {
//       sheets:pdf1,
//         imgSrc: img10,
//         title: "Tiger – 470 Wp",
//         alt: 'jinko-solar-panel-Tiger-470',
//         titles :'jinko-solar-panel-Tiger-470'
//     },
//     {
      
//         imgSrc: img11,
//         title: "Tiger Neo Bifacial – 72 Cell N-type",
//         alt: 'jinko-solar-panel-bifacial',
//         titles :'jinko-solar-panel-bofacial'
        
//     },
//     {
//         imgSrc: img12,
//         title: "Tiger Neo Mono – 78 Cell N-type",
//         alt: 'jinko-solar-panel-78',
//         titles :'jinko-solar-panel-78'
//     },
//     {
//         imgSrc: img13,
//         title: "Tiger Neo Mono – 72 Cell N-type",
//         alt: 'jinko-solar-panel-72',
//         titles :'jinko-solar-panel-72'
//     },
//     {
//         imgSrc: img14,
//         title: "Tiger Neo Bifacial – 78 Cell N-type",
//         alt: 'jinko-solar-panel-78',
//         titles :'jinko-solar-panel-78'
//     }

// ]

// const axitecsheetdata = [
//     {
//         imgSrc: img15,
//         title: "AXIworldpower – 335Wp NON-DCR",
//         alt: 'axitec-solar-panel-335wp',
//         titles :'axitec-solar-panel-335wp'
        
//     },
//     {
//         imgSrc: img16,
//         title: "AXIworldpremium XXL HC – 550Wp",
//         alt: 'axitec-solar-panel-550wp',
//         titles :'axitec-solar-panel-550wp'
//     },
//     {
//         imgSrc: img15,
//         title:  "AXIworldpower – 335Wp DCR",
//         alt: 'axitec-solar-panel-335wp-dcr',
//         titles :'axitec-solar-panel-335wp-dcr'
//     }
// ]

// const novasyssheetdata = [
//     {
//         imgSrc: img17,
//         title: "Vega Series – 335Wp DCR",
//         alt: 'nova-solar-panel-335wp-dcr',
//         titles :'nova-solar-panel-335wp-dcr'
//     },
//     {
//         imgSrc: img18,
//         title: "Alpha Plus Series – 550Wp",
//         alt: 'nova-solar-panel-550wp',
//         titles :'nova-solar-panel-550wp'
//     },
//     {
//         imgSrc: img17,
//         title: "Vega Series – 335Wp NON – DCR ",
//         alt: 'nova-solar-panel-335wp-dcr',
//         titles :'nova-solar-panel-335wp-dcr'
//     }
// ]


export default function productsheet() {
  const jinkodata = [
    {
      sheets:tigerbipdf,
      imgSrc: tigerbi,
      title: "Tiger Bifacial ",
      point1: "455-475 Watt",
      point2: "Efficiency - upto 20.87%",
      point3: "30 year linear power warranty",
      point4: "TR Technology  + Half Cell*",
    },
    {

      sheets:tigerpro,
      imgSrc: tigerproimg,
      title: "Tiger Pro Bifacial ",
      point1: "525-545 Watt",
      point2: "Efficiency - upto 21.10%",
      point3: "30 year linear power warranty",
      point4:'0.45% Annual Degradation*'

    },
    {
      sheets:pdf3,
      imgSrc: tigermono,
      title: "Tiger Pro Monofacial",
      point1: "535-555 Watt",
      point2: "Efficiency - upto 21.98%",
      point3: "25 year linear power warranty",
      point4: "0.55% Annual Degradation*",
    },
    {
      sheets:tigerneopdf,
      imgSrc:  tigerneo,
      title: "Tiger Neo Monofacial",
      point1: "565-585 Watt",
      point2: "Efficiency - upto 22.65%",
      point3: "30 year linear power warranty",
      point4: "0.40% Annual Degradation*",
    },
    {
      sheets:tigerneobipdf,
      imgSrc: tigerneobi,
      title: "Tiger Neo Bifacial",
      point1: "565-585 Watt",
      point2: "Efficiency - upto 22.64%",
      point3: "30 year linear power warranty",
      point4: "0.40% Annual Degradation*",
    },
    {
      sheets: tigerneobipdf2,
      imgSrc: tigerneobi,
      title: "Tiger Neo Bifacial",
      point1: "600-620 Watt",
      point2: "Efficiency - upto 22.18%",
      point3: "30 year linear power warranty",
      point4: "0.40% Annual Degradation* ",
    },
  ];



    const differenceData = [
        {
          sheets:pdf1,
          imgSrc: img1,
          title: "ASW LT-G3",
          point1: "20A inout current, ideal for bifacial and large area PV modules",
          point2: "3 MPPT´s for flexible PV array design",
          point3: " WMPPT´s for flexible PV array design",
          point4: " ShadeSol shadow management",
        },
        {
    
          sheets:pdf2,
          imgSrc: img2,
          title: " Three Phase Hybrid",
          point1: "User friendly app interface",
          point2: "Online monitoring via Wi-Fi and  Solplanet apps",
          point3: "Support unbalanced output",
          point4:'8/10/12kW 3-Phase Hybrid Inverter'
    
        },
        {
          sheets:pdf3,
          imgSrc: img3,
          title: "Single Phase Hybrid",
          point1: "User friendly app interface",
          point2: "Online monitoring via Wi-Fi and Solplanet apps",
          point3: " Easy to connect - battery and smart  meter interfaces",
          point4: " ShadeSol shadow management",
        },
        {
          sheets:pdf4,
          imgSrc: img4,
          title: "SOL EVPOWER",
          point1: "Scheduled EV charging",
          point2: "Adjustable charging power",
          point3: " RFID for user authentication",
          point4: " LED status indicators",
        },
        {
          sheets:pdf5,
          imgSrc: img5,
          title: "Single-Phase String Inverter",
          point1: "Supports up to 150% DC/AC ratio",
          point2: "Small and light thanks to compact design",
          point3: "Fast insatlltion with no lid opening necessary",
          point4: "IP65 for outdoor use",
        },
        {
          sheets:pdf6,
          imgSrc: img6,
          title: "ASW LT-G2 Pro",
          point1: "User friendly app interface",
          point2: "Max.20 A input current, ideal for bifacial and large area PV modules",
          point3: "Wide MPP voltage range 150V-1000V",
          point4: "ShadeSol shadow management",
        },
      ];
    
    // const handleDownload = (imgSrc) => {
    //     const link = document.createElement('a');
    //     link.href = imgSrc;
    //     link.download = 'image.jpg';
    //     link.click();
    // };
    return (
        <>
        <div>
            {/* <div className='productsheet'>
                <div className='section-title section-tit flex-column'>
                    <h2>Jinko Solar</h2>
                    <p className='text-dark col-md-9 text-center' style={{fontSize:'16px',fontWeight:500}}>Jinko Solar panels are known for their high conversion efficiency, cost-effective, well-designed, and High-Temperature Resistance. Jinko Solar offers both monocrystalline and polycrystalline solar panels. solar modules have strong frames and tempered glass to protect the solar cells. Jinko Solar invests in research and development, incorporating advanced technologies into their panels to improve performance and reliability. The popular Jinko solar modules are <b style={{cursor:'pointer'}}> <a href="./Jinko-Solar" className='text-dark'> Jinko Bifacial Solar Module </a></b>, N - Type Solar Panel, P-type Jinko Solar, Jinko monofacial 545,<b style={{cursor:'pointer'}}> <a href="./Jinko-Solar" className='text-dark'> Jinko Bifacial 530</a></b>, and Jinko Bifacial 545. You can esily buy Jinko solar panels at the best prices with Oneklick.</p>
                </div>
                <div className='section-content p-0'>
                    <div className='container'>
                        <div className='row sheets'>
                            {jinkosheetdata.map((item, index) => (

                                <div className='col-md-4 sheet-col' key={index}>
                                    <img src={item.imgSrc} alt={item.alt} title={item.titles} ></img>
                                    <h3>{item.title}</h3>
                                    <a  onClick={() => handleDownload(item.imgSrc)}><div className='download-btn'  style={{cursor:'pointer'}}>Download DataSheet</div></a>
                                </div>
                            )
                            )
                            }
                        </div>
                    </div>
                </div>
                <div className='section-title section-tit flex-column'>
                    <h2>AXITEC SOLAR</h2>
                    <p className='text-dark col-md-9 text-center' style={{fontSize:'16px',fontWeight:500}}>AXITEC Solar is a top solar panel manufacturer known for manufacturing high-quality solar panels. AXITEC solar panels are designed for high efficiency, reliable and consistent performance, long durability, strong warranty, and innovative technology. AXITEC solar modules are a reliable and efficient choice for homeowners and businesses seeking sustainable energy solutions. Here are some of the top solar modules and features. You can easily buy solar modules at the best price.
</p>
                </div>
                <div className='section-content'>
                    <div className='container'>
                        <div className='row sheets'>
                            {axitecsheetdata.map((item, index) => (

                                <div className='col-md-4 sheet-col' key={index}>
                                    <img src={item.imgSrc}  alt={item.alt} title={item.titles}></img>
                                    <h3>{item.title}</h3>
                                    <a  onClick={() => handleDownload(item.imgSrc)}><div className='download-btn' style={{cursor:'pointer'}}>Download DataSheet</div></a>
                                </div>
                            )
                            )
                            }
                        </div>
                    </div>
                </div>
                <div className='section-title section-tit flex-column'>
                    <h2>NOVASYS GREEN</h2>
                    <p className='text-dark col-md-9 text-center' style={{fontSize:'16px',fontWeight:500}}>Novasys solar panels deliver cutting-edge solar technology combining innovation and sustainability. Their top features include exceptional energy efficiency, boasting high conversion rates to maximize energy output. Their solar modules are eco-friendly, reducing carbon footprint and promoting a clean environment. Novasys  solar panels come with a reliable warranty, assuring customers of their performance and reliability. solar modules are a smart choice for those seeking sustainable energy solutions with top-notch performance and aesthetics.</p>
                </div>
                <div className='section-content'>
                    <div className='container'>
                        <div className='row sheets'>
                            {novasyssheetdata.map((item, index) => (

                                <div className='col-md-4 sheet-col' key={index}>
                                    <img src={item.imgSrc}  alt={item.alt} title={item.titles}></img>
                                    <h3>{item.title}</h3>
                                    <a  onClick={() => handleDownload(item.imgSrc)}><div className='download-btn'  style={{cursor:'pointer'}}>Download DataSheet</div></a>
                                </div>
                            )
                            )
                            }
                        </div>
                    </div>
                </div>

            </div> */}



             <div className="child-container">
            <div className="col-md-8 header-section">
              <h2
                data-aos-duration="800"
                data-aos="fade-right"
                data-aos-once="true"
              >
              Jinko Solar
              </h2> 
            </div>
          </div>
             <div className="differences mt-5">
            <div className="section-content p-1">
              <div
                className="row col-md-10 "
                style={{ justifyContent: "space-around", margin: "auto" }}
              >
                {jinkodata.map((item, index) => (
                  <div
                    className="col-md-4  "
                    data-aos="flip-up"
                    data-aos-once="true"
                    key={index}
                  >
                    <div className="product-imgs product-hover bg-light">
                        <div className="text-center">
                      <img
                        src={item.imgSrc}
                        height={"250px"}
                        width={"100px"}
                        className="img-icon"
                        alt={item.alt}
                        title={item.title}
                      />
                      </div>
                      <div className="overlay">
                        <div className="text"></div>
                      </div>
                      <h3 className="">{item.title}</h3>
                    </div>
                    <div className="flex-column d-flex">
                    <p
                      className="differnces flex-column"
                      style={{
                        float: "left",
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      <li>{item.point1}</li>
                      <li>{item.point2}</li>
                      <li>{item.point3}</li>
                      <li>{item.point4}</li>
                    </p>
                    <div className="mb-5 mt-2 d-flex justify-content-center align-items-center">
                    <a className="btn text-light downloadsheet" onClick={()=>window.open(item.sheets)}>Download Datasheet</a>
                    </div>
                    </div>
                  </div>
                  
                ))}
 
              </div>
            </div>
          </div>


          <div className="child-container">
            <div className="col-md-8 header-section">
              <h2
                data-aos-duration="800"
                data-aos="fade-right"
                data-aos-once="true"
              >
              Solplanet Inverter
              </h2> 
            </div>
          </div>
             <div className="differences mt-5">
            <div className="section-content p-1">
              <div
                className="row col-md-10 "
                style={{ justifyContent: "space-around", margin: "auto" }}
              >
                {differenceData.map((item, index) => (
                  <div
                    className="col-md-4  "
                    data-aos="flip-up"
                    data-aos-once="true"
                    key={index}
                  >
                    <div className="product-imgs product-hover bg-light">
                        <div className="text-center">
                      <img
                        src={item.imgSrc}
                        height={"250px"}
                        width={"100px"}
                        className="img-icon"
                        alt={item.alt}
                        title={item.title}
                      />
                      </div>
                      <div className="overlay">
                        <div className="text"></div>
                      </div>
                      <h3 className="">{item.title}</h3>
                    </div>
                    <div className="flex-column d-flex">
                    <p
                      className="differnces flex-column"
                      style={{
                        float: "left",
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      <li>{item.point1}</li>
                      <li>{item.point2}</li>
                      <li>{item.point3}</li>
                      <li>{item.point4}</li>
                    </p>
                    <div className="mb-5 mt-2 d-flex justify-content-center align-items-center">
                    <a className="btn text-light downloadsheet" onClick={()=>window.open(item.sheets)}>Download Datasheet</a>
                    </div>
                    </div>
                  </div>
                  
                ))}
 
              </div>
            </div>
          </div>

          

        </div>

          <div className="image-container col-md-12 img-fluid solplanet-container" title="solex-panel" alt="solex-panel">
        </div>
        {/* tree section */}
        
        <Footer/>
        </>
        
    )

}


