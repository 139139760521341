import React from 'react'
export default function turnel() {
  return (
    <div>
        <div className="image-container col-md-12 img-fluid ps-turnel-image"  title='jiko-solar-panel' alt="jiko-solar-panel">
     
        </div>
      
    </div>
  )
}
