import React from 'react'

const usebleaccordin = ({detail}) => {
  return (
    <div>
        <div className="d-flex justify-content-center align-items-center faq-context m-0 py-0"
             data-aos-duration="800"
             data-aos="zoom-out"
             data-aos-once="true">
        <div
          class="accordion accordion-flush col-md-12 w-100 m-0"
          id="accordionFlushExample"
        >
          {detail.map((item, index) => {
            return (
              <div class="accordion-item acc-item mb-2 bg-transparent  " key={index} >
                <h4
                  class="accordion-header acc-head border-none "
                  id={`flush-headingOne ${index}`}
                  
                >
               
                  <button
                    class="accordion-button acc-btn acc-btn collapsed bg-transparent  fw-5"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${index}`}
              
                    aria-controls={`flush-collapse${index}`}
                    
                  >
                    {item.question}
                  
                   
                  </button>
                </h4>
                <div
                  id={`flush-collapse${index}`}
                  class="accordion-collapse collapse "
                  aria-labelledby={`flush-heading${index}`}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body acc-bdy text-start text-dark " style={{fontSize:'15px'}}>{item.answer}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      
    </div>
  )
}

export default usebleaccordin
