import React from 'react'

const solplanetcard = () => {
  const values = [
    {
      title:'17',
      subtitle:'Years of PV Know-how'
    },
    {
      title:'Top 12',
      subtitle:'Ranking to Top 12 in Global Inverter Shipment'

    },
    {
      title:'40+sites',
      subtitle:'Asia, Europe, Africa, America, Oceania'

    },
    {
      title:'200+',
      subtitle:'Patents and copyrights'

    },
    {
      title:'10GW',
      subtitle:'Annual Capacity'

    },
    {
      title:'1 Million',
      subtitle:'Inverters production capacity / year'

    },
    {
      title:'40,000',
      subtitle:'SQM Factory Land'

    },
    {
      title:'ISO',
      subtitle:'Quality Standards'

    },
    {
      title:'2021',
      subtitle:'Red Dot Award'

    }
  ]
  return (
    <div className='mx-3'>
      <div className="solplanet-cards col-md-12 col-12 m-auto mt-5">
        <div className="row col-md-12 col-lg-9 cols-12 col-sm-8">
        {
          values.map((item,index)=>{
            return(
              <div className="sol-card col-md-4 px-2 my-1  col-sm-12  col-12 " key={index}>
                <div className="card-context m-1 p-3">
                  <div className="align-card">
              <p className='fs-5 m-0'><b> {item.title}</b></p>
              <p className='fs-6 m-0'>{item.subtitle}</p>
              </div>
              </div>
          </div>
            )
          })
        }
        </div>
      </div>
    </div>
  )
}

export default solplanetcard
