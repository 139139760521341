import React from "react";
import Navbar from "./Navbar";
import Footer from "./footer"
import blog_img from "../images/Oneklick-blog.webp"
import post_img from "../images/Authorized-Distributor-in-india.webp"
import "./newblog.css?v=1.1";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import blogtem from "./blog-page"
import { Link } from "react-router-dom";
import "./blog-page"
export default function newblog() {



  return (
    <>
      <Navbar />
      <HelmetProvider>
      <Helmet>
  <title>Solar Panels Energy Blog | Oneklick Tecno Renewable </title>
  <meta name="description" content=" Read the latest articles on the solar industry and renewable solar energy sector here. Oneklick publishes top solar energy and solar solutions blogs for a greener future.
"/>
<meta name="keywords" content=" solar panel distributor in india, solar panels blog, solar modules article, renewable solar energy, solar panels, solar modules prices, solar panels EPC services"/>
<link rel="canonical" href="https://oneklick.in/blog" />
<meta name="twitter:title" content="Solar Panels Energy Blog | Oneklick Tecno Renewable " />
<meta name="twitter:description" content=" Read the latest articles on the solar industry and renewable solar energy sector here. Oneklick publishes top solar energy and solar solutions blogs for a greener future.
" />
<meta property="og:title" content="Solar Panels Energy Blog | Oneklick Tecno Renewable "/>
<meta property="og:description" content="Read the latest articles on the solar industry and renewable solar energy sector here. Oneklick publishes top solar energy and solar solutions blogs for a greener future.
"/>
<meta property="og:url" content="https://oneklick.in/blog" />
    </Helmet>
    </HelmetProvider>
    
      <div class="col-md-12 warehouse-main-container">
        <div className="row row1">
        <div className="col-md-6 firstCol">
          <h1 className="aboutHeading" >Blog</h1>
          <div className="button1">
            <a href="">
              Home<i class="fa-solid fa-angle-right"></i>
              <span>Blog</span>
            </a>
          </div>
        </div>
          <div className="col-md-6 secondCol">
            <img
              className="image img-fluid"
              src={blog_img}
              alt="oneklick-blog-img"
              title="oneklick-blog-img"
            />
          </div>
        </div>
      </div>

        <div className="newblog-parent col-md-12">
          
      <div className="newblog-container col-md-10">
      <img src={post_img} className="img-fluid" alt="modules-img" title="modules-img" />
      <div className="blog-header-title">
                <h3>OneKlick is an Authorised Distributor of Jinko Solar, Solex,Novasys Green and Axitec.</h3>
                <p>OneKlick is a leading solar module authorized distributor. We offer high-quality modules from globally recognized brands like Jinko Solar, Solex, Axitec Solar, and Novasys Green. We strive to provide our clients with cutting edge solar technologies and ensure that our modules are built to the highest standards of quality and reliability.
                Authorized distributor of Top Solar Module Manufacturers.</p>
                <li>Authorized distributor of Top Solar Module Manufacturers</li>
                <li>Competitive Pricing</li>
                <li>Strong and Reliable distribution network</li>
                <li>Smooth and easy order process</li>
                <li>On Time Deliveries</li>
                <li>Commitments delivered</li>
                <li>Team of Experienced professionals with Ex-IITian as one of the founder</li>

            </div>
      <div className="blog-header-title col-md-8">
            <h3>Tanmay Rishi Shah&#128516;: The Charismatic Child Actor and Brand Ambassador for Oneklick Techno Renewable Pvt Ltd...</h3>
            <Link to="/blog-page/tanmay">Read More</Link>
            </div>
            <div className="blog-header-title col-md-8">
            <h3>Harnessing Solar Power: Solplanet Inverters and Shadesol Technology...</h3>
            <Link to="/blog-page/solplanet">Read More</Link>
            </div>
        <div className="row ">

           

          <div className="left-newblog-container col-md-8">
            {/* <img src={newblogimg} alt="" />

            <div className="blog-header-title">
                <h3>Service Construct deals physical damage with his basic attack in the match.</h3>
                <p>There are many variations of passages agency we have covered many special events such as fireworks, fairs, parades, races, walks, a Lorem Ipsum Fasts injecte.</p>
            </div> */}
            
          
            {/* <img src={newblogimg} alt="" /> */}

            {/* <div className="numbers">
                <div className="row order-list">
                    <ul className="unorder">
                        <li><i class="fa-solid fa-arrow-left m-auto"></i></li>
                        <li>1</li>
                        <li>2</li>
                        <li>3</li> 
                        <li><i class="fa-solid fa-arrow-right m-auto "></i></li>
                    </ul>
                </div>
            </div> */}
          </div>
          

          <div className="right-newblog-container col-md-4">
            <div className="inner-container">
                {/* <div class="input-group search-bar-container">
                <div class="form-outline">
                    <input type="search" id="form1" class="form-control blog-search" placeholder="   Search" />
                   
                </div>
                <button type="button" class="btn btn-primary blog-btn">
                    <i class="fas fa-search"></i>
                </button>
                </div>   */}
               
                {/* <div className="post-icon-container">
                <h4 className="mb-4">Recent</h4>
                    <div className="row ">
                       
                        <div className="col-md-3 img-containers">
                            <img src={icon1} alt="" />
                        </div>
                        <div className="context-value col-md-9">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Natus, illum in nesciunt tempora porro asperiores veritatis eos facilis corrupti dicta.</p>
                        </div>

                        <div className="col-md-3 img-containers">
                            <img src={icon1} alt="" />
                        </div>
                        <div className="context-value col-md-9">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Natus, illum in nesciunt tempora porro asperiores veritatis eos facilis corrupti dicta.</p>
                        </div>

                        <div className="col-md-3 img-containers">
                            <img src={icon1} alt="" />
                        </div>
                        <div className="context-value col-md-9">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Natus, illum in nesciunt tempora porro asperiores veritatis eos facilis corrupti dicta.</p>
                        </div>
                    </div>
                </div> */}

                {/* <div className="post-icon-container">
                    <div className="row">
                    <h4 className="mb-4">Categories</h4>
                        <div className="context-value col-md-9 second-context-value">
                           <a href=""><i class="fal fa-angle-right"></i>Technology</a>
                           <a href=""><i class="fal fa-angle-right"></i>Software</a>
                           <a href=""><i class="fal fa-angle-right"></i>hardware</a>
                           <a href=""><i class="fal fa-angle-right"></i>Tech industrial</a>
                           <a href=""><i class="fal fa-angle-right"></i>Marketing</a>
                        </div>
                    </div>
                </div> */}
                </div>
            </div>

       

        </div>
      </div>
      </div>

      <Footer />
    </>
  );
}
