import React, { useEffect,useState } from "react";
import Axitec from "../images/waxitec.png";
import Jinko from "../images/wjinko.png";
import Nova from "../images/wnova.png";
import "../Product.css?v=1.1"
import Navbar from "./Navbar"
import Productsheet from "./productsheet"
import Swal from 'sweetalert2'
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Product() {




  // useEffect(() => {
  //   const forms = document.querySelectorAll(".needs-validation");

  //   // Loop over them and prevent submission
  //   Array.from(forms).forEach((form) => {
  //     form.addEventListener("submit", (event) => {
  //       if (!form.checkValidity()) {
  //         event.preventDefault();
  //         event.stopPropagation();
  //       }

  //       form.classList.add("was-validated");
  //     }, false);
  //   });
  // }, []);

  // useEffect(() => {
  //   const Fetchoffer = () => {
  //     const btn = document.querySelector('.submitbtns');
  //     const form = document.querySelector('.form-container');
  
  //     btn.addEventListener('click', (e) => {
  //       e.preventDefault(); 

  //       const value = (Math.random()*0.1).toFixed(3);

  //       if (form.checkValidity()) {
  //         if(value >= 0.01){
  //           setMessage(`Hurrah you get a discount off Rs. ${value} `);
  //         }
  //         else{
  //           setMessage('Better Luck Next Time');
  //         }
        
  //       } else {
  //         setMessage(''); 
  //       }
  //     });
  //   }

  //   Fetchoffer();
  // }, []); 


  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [product, setProduct] = useState('');
const[message,setMessage] =useState('')
  const [validation , setvalidation] = useState(false)
console.log(message)

  const sendEmail = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
        if(form.checkValidity() === false){
          e.stopPropagation();
        }else{
          if(name === '' && email === '' && contact === '' &&  product == '')  {
            Swal.fire({
              icon: 'error',
              title: 'Invalid Name',
              text: 'Please Fill The All Details',
            });
            return;
          }
       
        else if 
        (name === '') {
          Swal.fire({
            icon: 'error',
            // title: 'Invalid Name',
            text: 'Please Enter Your Name',
          });
          return;
        }
        else if
          (email === '') {
            Swal.fire({
              icon: 'error',
              // title: 'Fill The Ema',
              text: 'Please Enter Your Email',
            });
            return;
          }
        else if (contact.length < 10) {
          Swal.fire({
            icon: 'error',
            // title: 'Invalid Contact Number',
            text: 'Please enter a valid contact number with at least 10 digits',
          });
          return;
        }
        else if (product === '') {
          Swal.fire({
            icon: 'error',
            // title: 'Invalid Contact Number',
            text: 'Select The Module ',
          });
          return;
        }
      }
    try {
      $.ajax({
        url: 'https://appj.scaleedge.in/register',
        type: 'POST',
        data: { subject:'Enquiry For Module',message:message, name : name, email: email, contact : contact,product : product },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (response) {
            console.log('Email sent successfully');
            Swal.fire(
              'Good job!',
              'Mail Send Successfully',
              'success'
            );
            console.log(response);
            setName('');
            setEmail('');
            setContact('');
            setProduct('');
           
        },
        error: function (err) {
          console.log(err);
            //alert(11);
            // alert(err.responseText);
        }
      });
      setvalidation(true);
    }
    catch(error){
      console.log(error);
    }
  }
  
  return (
    <div>
      <React.Fragment>
      <Navbar/>
      <HelmetProvider>
      <Helmet>
  <title>Buy Solar Panels In India  - Oneklick Techno Renewable</title>
  <meta name="description" content="Oneklick is authorised solar panels distributor in India. We offers top solar modules inculding Jinko, solex, novasays and Axitec solar modules for residential and commercial.
"/>
<meta name="keywords" content="Jinko solar, novasys solar, Buy Solar Panel, solar module distributors, Solar Panel, Solar Panel Price, solar panels distributor, Axitec Solar, Bifacial Solar Panel, Jinko Bifacial 530, Jinko Bifacial 545, Jinko N type Module,novasys solar panel, Solar Module Distributor in india, Solar Module Price, Solex Solar
"/>

<link rel="canonical" href="https://oneklick.in/products" />
<meta name="twitter:title" content="Solar Panel Price In India | Buy Solar Modules at OneKlick" />
<meta name="twitter:description" content=" Oneklick is authorised solar panels distributor in India. We offers top solar modules inculding Jinko, solex, novasays and Axitec solar modules for residential and commercial.
" />
<meta property="og:title" content="Solar Panel Price In India | Buy Solar Modules at OneKlick"/>
<meta property="og:description" content=" Oneklick is authorised solar panels distributor in India. We offers top solar modules inculding Jinko, solex, novasays and Axitec solar modules for residential and commercial.
"/>
<meta property="og:url" content="https://oneklick.in/products" />

    </Helmet>
    </HelmetProvider>
        <div className="col-md-12 product-main-container">
          <div className="row product-row">
            <div className="col-md-8 product-left-container">
              <p className="product-title fs-1 mb-0">Authorised Distributor of</p>
              <div className="row">
                <div className="col-md-12">
                <div className="title-header" id="title-head">
                <h1 className="fs-1 pb-2">JINKO SOLAR</h1>
                <p className="fs-1">SOLPLANET INVETER</p>
                
              </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 product-right-container">
              <div className="product-right-content">
                <form className="needs-validation form-container" noValidate>
                  <p className="head-title text-center mb-3 p-0" style={{fontSize:'30px',color:'white'}}> <p className="fw-5 fs-4 fw-light p-0 mb-0 lh-sm">Contact Form</p></p>
                  <div className="form-group me-class">
                    <input
                      type="text"
                      
                      className="form-control product-form-control"
                      id="validationCustom01"
                      aria-describedby="emailHelp"
                      placeholder="Name"
                      autoComplete="off"
                      required
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      
                    />
                  </div>
                  <div className="form-group product-form-group  me-class">
                    <input
                      type="email"
                      className="form-control product-form-control"
                      id="validationCustom03"
                      placeholder="Email"
                      required
                      name="email"
                      value={email}
                    
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group product-form-group  me-class">
                    <input
                      type="number"
                      className="form-control product-form-control"
                      id="validationCustom05"
                      placeholder="Number"
                      required
                      name="number"
                      value={contact}      
                      onChange={(e) => setContact(e.target.value)}
                    />
                  </div>
                  <div className="form-group product-form-group  me-class">
                    <input
                      type="text"
                      className="form-control product-form-control"
                      id="validationCustom04"
                      placeholder="Requirement"
                      required
                      name="module"
                      value={product}      
                      onChange={(e) => setProduct(e.target.value)}
                    />
                  </div>
                  {/* <div className="form-group w-75 products-form  me-class" >
                    <select
                      className="form-control product-form-control"
                      id="validationCustom04"
                      placeholder="Select Module"
                      required
                      style={{borderRadius:'10px'}}
                      name="module"
                      value={product}      
                      onChange={(e) => setProduct(e.target.value)}>
                      <option value="" style={{color:'white'}}>Select Module</option>
                      <option style={{color:'white'}}>Jinko - 550Wp</option>
                      <option style={{color:'white'}}>Jinko - 470Wp</option>
                      <option style={{color:'white'}}>Axitec - 550Wp</option>
                      <option style={{color:'white'}}>Axitec - DCR - 335WP</option>
                      <option style={{color:'white'}}>Axitec - Non DCR - 335WP</option>
                      <option style={{color:'white'}}>Novasys - 540Wp</option>
                      <option style={{color:'white'}}>Novasys - DCR - 335Wp</option>
                      <option style={{color:'white'}}>Novasys - Non DCR - 335Wp</option>
                    </select>
                  </div> */}
                  <div className="col-md-12 message-con  me-class ">
                    <input
                      type="text"
                      class="form-control  "
                      name="message"
                      value={message}
                      placeholder="MESSAGE"
                    />
                  </div>
                  {/* <input type="text" readOnly className="col-md-12 rounded-3 readvalue" style={{fontSize:'15px'}} value={message} /> */}
                  <button type="submit" onClick={sendEmail} className="btn btn-dark btn-md mt-3 me-class product-btn submitbtns" style={{borderRadius:'10px'}}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Productsheet/>

      </React.Fragment>
    </div>
  );
}