import React from "react";
import Footer from "./footer";
import tanmay from "../images/Tanmay Rishi Shah The Charismatic Child Actor and Brand Ambassador for Oneklick Techno Renewable Pvt Ltd.webp";
import solarplant from "../images/Harnessing Solar Power Solplanet Inverters and Shadesol Technology.webp";
import Navbar from "./Navbar";
import { useLocation } from "react-router-dom";
const blogtemplate = (props) => {
  const location = useLocation();
  const currentroute = location.pathname.split("/")[2];


  let blogData;

  if (currentroute === "tanmay") {
    blogData = [
      {
        title:
          "Tanmay Rishi Shah: The Charismatic Child Actor and Brand Ambassador for Oneklick Techno Renewable Pvt Ltd",
        blogimg: tanmay,
        paragraph: `In the dynamic world of renewable energy, the need for sustainable solutions is more pronounced than ever. One company that stands at the forefront of this movement is Oneklick Techno Renewable Pvt Ltd, a firm dedicated to providing innovative and eco-friendly energy solutions. As they continue their mission to promote sustainability and harness clean energy, they have found an inspiring ally in the form of Tanmay Rishi Shah, a charismatic child actor who has taken on the role of their esteemed brand ambassador.  </br>
        </br>
      
  <b> <h2 class="fs-3 fw-bolder px-0"> A Rising Star with a Heart for a Sustainable Future: </h2></b> </br> Tanmay Rishi Shah, a budding child actor with a significant following, has captivated audiences with his talent and charm on screen. Beyond his acting prowess, Tanmay possesses a genuine passion for making a positive difference in the world, particularly in the realm of environmental sustainability.
  </br>
  </br>
  His advocacy for environmental causes has been noticed by Oneklick Techno Renewable Pvt Ltd, a company that aligns with Tanmay's commitment to creating a cleaner, greener future. The partnership between Tanmay Rishi Shah and Oneklick Techno Renewable Pvt Ltd is a testament to their shared vision of fostering a sustainable planet for future generations.
  </br>
  </br>
  <b> <h3 class="fs-3 fw-bolder px-0">Spreading Awareness and Inspiring Change: </h3></b> </br>  As a brand ambassador, Tanmay Rishi Shah is dedicated to spreading awareness about the importance of renewable energy and sustainable practices. Through various initiatives, campaigns, and outreach programs, he aims to educate people about the benefits of embracing renewable energy solutions and adopting a greener lifestyle.
  </br>
  </br>
  Tanmay's influence and engaging persona will undoubtedly encourage more individuals to make environmentally conscious choices, reinforcing Oneklick Techno Renewable Pvt Ltd's mission to drive the adoption of clean energy technologies.
  </br>
  </br>
  <b> <h3 class="fs-3 fw-bolder px-0">A Synergistic Partnership:</h3> </b> </br>The collaboration between Tanmay Rishi Shah and Oneklick Techno Renewable Pvt Ltd is a synergistic partnership that amplifies their shared commitment to a sustainable future. Tanmay's involvement as a brand ambassador not only enhances the company's image but also demonstrates their dedication to making a meaningful impact on the environment. Together, they strive to inspire change, advocate for renewable energy, and encourage a shift towards a cleaner and more sustainable world. Tanmay Rishi Shah's influence and passion, coupled with Oneklick Techno Renewable Pvt Ltd's innovative solutions, create a powerful force for positive transformation in the realm of renewable energy.
  </br>
  </br>
  <b> <h4 class="fs-3 fw-bolder px-0">Conclusion: </h4></b> </br> In conclusion the association between Tanmay Rishi Shah and Oneklick Techno Renewable Pvt Ltd is a beacon of hope for a brighter, greener future. Through their combined efforts, they are igniting a movement towards sustainability and encouraging us all to play a part in preserving our planet for generations to come.`,
      },
    ];
  } else if (currentroute === "solplanet") {
    blogData = [
      {
        title:
          "Harnessing Solar Power: Solplanet Inverters and Shadesol Technology...",
        blogimg: solarplant,
        paragraph: `In a world increasingly seeking sustainable energy solutions, solar power stands out as a promising avenue. Solar energy offers a clean, renewable source of power, and as technology advances, harnessing this abundant resource becomes more efficient and cost-effective. One key player in this domain is Solplanet, a company at the forefront of solar inverter technology, particularly known for their innovative Shadesol technology. 
      </br>
      </br>
      <b><h2 class="fs-3 fw-bolder px-0" > The Power of Solar Inverters : </h2> </b></br>
        Solar inverters are critical components in any solar power system. They convert the direct current (DC) generated by solar panels into alternating current (AC), which is the form of electricity used in our homes and businesses. This conversion process is vital for making solar energy usable and integrating it into the existing power grid.
        </br>
        </br>
        <b><h3 class="fs-3 fw-bolder px-0" > A Pioneer in Solar Inverter Technology : </h3></b></br>
        Solplanet is a trailblazer in the renewable energy industry, specializing in developing cutting-edge solar inverters. They're recognized for their commitment to innovation and sustainability, continually pushing the boundaries to enhance the efficiency and reliability of solar power systems.
        </br>
        </br>
        <b><h3 class="fs-3 fw-bolder px-0" >  Introducing Shadesol Technology :  </h3></b> </br>
        Shadesol is a revolutionary technology developed by Solplanet that addresses a common issue faced by solar panels – temperature-induced power losses. When solar panels heat up due to exposure to sunlight, their efficiency decreases, leading to lower energy production.
        
        Shadesol technology mitigates this problem by utilizing smart control algorithms within the solar inverters. These algorithms optimize the operation of the solar panels based on real-time weather and temperature data. By doing so, Shadesol helps maintain the panels at an optimal temperature, ensuring they operate at peak efficiency, even in varying weather conditions.
        </br>
        </br>
        <b><h3 class="fs-3 fw-bolder px-0" > The Benefits of Shadesol Technology :</h3> </b>  </br>
        Enhanced Efficiency: Shadesol technology significantly improves the overall efficiency of solar panels by minimizing the impact of temperature-induced losses. This means more power is generated, maximizing the return on investment for solar power system owners.
        </br>
        </br>
        <b> <h3 class="fs-3 fw-bolder px-0" >Longer Lifespan: </h3></b> </br>
         By reducing stress on the solar panels caused by excessive heat, Shadesol helps extend their lifespan. This longevity is essential for a sustainable solar energy setup, contributing to a lower environmental impact.
          </br>
          </br>
       <b> <h3 class="fs-3 fw-bolder px-0" >Consistent Performance: </h3> </b> </br>
         Regardless of weather changes, Shadesol technology maintains a consistent and optimized performance of the solar panels, ensuring a reliable source of clean energy.</br>
         </br>
       <b> <h3 class="fs-3 fw-bolder px-0" >Empowering a Greener Future : </h3> </b></br>
        Solplanet's commitment to technological innovation and sustainability through Shadesol technology is pivotal in advancing the adoption of solar energy. By continually improving efficiency, reducing costs, and enhancing the overall performance of solar power systems, Solplanet is empowering individuals and communities to embrace a greener, more sustainable future.
        </br>
        </br>   

        <b><h4 class="fs-3 fw-bolder px-0" >conclusion :</h4></b> </br> Solplanet's innovative Shadesol technology demonstrates how advancements in solar inverter technology can significantly impact the efficiency and effectiveness of solar energy generation. As we continue down the path of sustainability, solutions like Shadesol play a crucial role in realizing a cleaner and more sustainable world powered by solar energy.  
        `,
      },
    ];
  }

  return (
    <>
      <Navbar />

      <div className="newblog-container col-md-12 mx-4">
        <div className="blog-header-title col-md-8 col-12">
          <h3>{blogData[0].title}</h3>
          <div className="col-md-8 my-4">
            <img
              className="image"
              src={blogData[0].blogimg}
              alt="oneklick-blog-img"
              title="oneklick-blog-img"
            />
          </div>
          <p
            dangerouslySetInnerHTML={{ __html: blogData[0].paragraph }}
            className="text-capitalize"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default blogtemplate;
