import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useEffect, useState } from "react";
import React from "react";
import "./campain.css?v=1.1";
import Parallax from "./parallax";
import "aos/dist/aos.css";
import "@splidejs/react-splide/css/sea-green";
import test_img from "../../images/harsh.webp";
import test_img2 from "../../images/harvinder.webp";
import test_img3 from "../../images/Simran.webp";
import test_img4 from "../../images/Sharad.webp";
import Turnel from "./Campain_turnelS";
import fgmr from "../../images/bifacial-p-solar panels.webp";
import fgml from "../../images/mono-porc-cell.webp";
import jinkoproduct from "../../images/jinko-product.png";
import jinkopdt from "../../images/tiger_panel_left - Tiger Monofacial.png";
import jinkopdt2 from "../../images/tiger_panel_right - Tiger Bifacial.png";
import jinkopdt3 from "../../images/tiger2 - 465 Ptype Bifacial.png";
import jinkopdt4 from "../../images/Tiger Bifacial - Ntype.png";
import Aos from "aos";
import sheetone from "../../images/JKM565-585N-72HL4-(V)-F2-EN(IEC 2005).PDF"
import sheetsec from "../../images/TR JKM455-475M-7RL3-TV-A3-EN(IEC 2005).pdf"
import sheetthr from "../../images/JKM525-545M-72HL4-TV-F3-EN-For India.pdf"
import sheetfour  from "../../images/JKM535-555M-72HL4-(V)-F3-EN-For India.pdf"
import sheetfive from "../../images/JKM600-620N-78HL4-BDV-F4-EN (IEC 2005).pdf"
import sheetsix from "../../images/JKM565-585N-72HL4-BDV-F4-EN (IEC 2005).pdf"
import { Link } from "react-router-dom";
<link
  href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap"
  rel="stylesheet"
></link>;
export default function Firstcontainer() {
  <Splide
    options={{
      rewind: true,
      width: 100,
      gap: "1rem",
    }}
  ></Splide>;

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  const [perPage, setPerPage] = useState(2);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1200) {
        setPerPage(1);
      } else {
        setPerPage(2);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const differenceData = [
    {
      sheets:sheetone,
      imgSrc: jinkoproduct,
      title: "Tiger Bifacial",
      point1: "455-475 Watt",
      point2: "Efficiency - upto 20.87%",
      point3: "30 year linear power warranty",
      point4: "TR Technology  + Half Cell",
    },
    {

      sheets:sheetsec,
      imgSrc: jinkopdt,
      title: "Tiger Pro Bifacial",
      point1: "525-545 Watt",
      point2: "Efficiency - upto 21.10%",
      point3: "30 year linear power warranty",
      point4: "0.45% Annual Degradation*",
    },
    {
      sheets:sheetthr,
      imgSrc: jinkopdt2,
      title: "Tiger Pro Monofacial",
      point1: "535-555 Watt",
      point2: "Efficiency - upto 21.98%",
      point3: "25 year linear power warranty",
      point4: "0.55% Annual Degradation*",
    },
    {
      sheets:sheetfour,
      imgSrc: jinkopdt3,
      title: "Tiger Neo Monofacial",
      point1: "565-585 Watt",
      point2: "Efficiency - upto 22.65%",
      point3: "30 year linear power warranty",
      point4: "0.40% Annual Degradation*",
    },
    {
      sheets:sheetfive,
      imgSrc: jinkopdt4,
      title: "Tiger Neo Bifacial",
      point1: "565-585 Watt",
      point2: "Efficiency - upto 22.64%",
      point3: "30 year linear power warranty",
      point4: "0.40% Annual Degradation*",
    },
    {
      sheets:sheetsix,
      imgSrc: jinkoproduct,
      title: "Tiger Neo Bifacial",
      point1: "600-620 Watt",
      point2: "Efficiency - upto 22.18%",
      point3: "30 year linear power warranty",
      point4: "0.40% Annual Degradation*",
    },
  ];

  return (
    <>
      <section>
        <div className="col-md-12 first-container">
          <div
            className="col-md-12 header-section flex-column  d-flex justify-content-center align-items-center"
            style={{ marginTop: "100px" }}
          >
            <h2
              className="col-md-8 px-2"
              data-aos-duration="800"
              data-aos="fade-right"
              data-aos-once="true"
            >
              Jinko Solar Product
            </h2>
            <p className="col-md-8 text-start p-4"
                   data-aos-duration="800"
                   data-aos="fade-left"
                   data-aos-once="true">
              OneKlick Techno Renewable is the leading distributor of Jinko
              Solar in India. Jinko Solar is a world-renowned solar brand
              offering a wide range of solar modules suitable for homes and
              commercials. The most popular Jinko solar modules are Tiger Neo,
              Tiger Pro, Tiger Monofacial, Tiger Bifacial, N-Type Modules,and
              <b>
                {" "}
                <Link
                  to="../solar-product"
                  style={{ cursor: "pointer", color: "black" }}
                >
                  Bifacial Solar Module
                </Link>
              </b>
              . The price of Jinko solar panels starts from Rs. 22 per watt to
              Rs. 30 per watt and varies from location and type of solar panels.
            
            <br />
            <br />
            OneKlick is an authorized Jinko Solar distributor in India, offering a variety of Jinko solar modules and panels. Being able to provide customers with a range of options, including Jinko Bifacial 530, Jinko Bifacial 545, Jinko monofacial 545, Jinko N-type Modules, N-Type Solar Panels, and N-type Solar Modules, gives customers the flexibility to choose the right solar solution for their specific needs.
            </p>
          </div>
          <div className="differences">
            <div className="section-content p-1">
              <div
                className="row col-md-10 "
                style={{ justifyContent: "space-around", margin: "auto" }}
              >
                {differenceData.map((item, index) => (
                  <div
                    className="col-md-4  "
                    data-aos="flip-up"
                    data-aos-once="true"
                    key={index}
                  >
                    <div className="product-imgs product-hover bg-light">
                      <img
                        src={item.imgSrc}
                        height={"250px"}
                        width={"100px"}
                        className="img-icon"
                        alt={item.alt}
                        title={item.title}
                      />
                      <div className="overlay">
                        <div className="text"></div>
                      </div>
                      <h3 className="">{item.title}</h3>
                    </div>
                    <div className="flex-column d-flex">
                    <p
                      className="differnces flex-column"
                      style={{
                        float: "left",
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      <li>{item.point1}</li>
                      <li>{item.point2}</li>
                      <li>{item.point3}</li>
                      <li>{item.point4}</li>
                    </p>
                    <div className="mb-5 mt-2 d-flex justify-content-center align-items-center">
                    <a className="btn text-light downloadsheet" onClick={()=>window.open(item.sheets)}>Download Datasheet</a>
                    </div>
                    </div>
                  </div>
                  
                ))}
                
              </div>
            </div>
          </div>
          <div className="child-container">
            <div className="col-md-8 header-section">
              <h2
                data-aos-duration="800"
                data-aos="fade-right"
                data-aos-once="true"
              >
                Jinko Solar: Bankable, Performer, Achiever
              </h2>
              <p
                data-aos-duration="800"
                data-aos="fade-left"
                data-aos-once="true"
              >
                <li>
                  Jinko solar Ranked "Most Bankable" PV brand by Bloomberg New
                  Energy for 8 consecutive years
                </li>
                <li>
                  Jinko Recognized as Top Performer for PVEL's 2022 PV Module
                  Reliability Scorecard for the Ninth Consecutive Time
                </li>
                <li>
                  Its Recognized as Top Performer in PVEL/DNV GL 2019 PV Solar
                  Module Reliability Scorecard for the 5th Consecutive Year
                </li>
                <li>
                  Recognized as “Overall High Achiever” in RETC’s Jinko PV
                  Module Index Report for Third Consecutive Year
                </li>
              </p>
              <h2
                data-aos-duration="800"
                data-aos="fade-right"
                data-aos-once="true"
                style={{ marginTop: "60px" }}
                className="px-1 hide-tit"
              >
                Jinko Bifacial Solar Modules with Transparent Backsheet
                Technology
              </h2>
              <p
                     data-aos-duration="800"
                     data-aos="fade-left"
                     data-aos-once="true"
              >
                Jinko is known for its innovative solar technologies. The
                creation of bifacial solar panels with transparent backsheet
                technology is one of their noteworthy contributions to solar
                panel technology.
                <li className="mt-3">
                  <b> Bifacial Solar Modules:</b> These{" "}
                  <b>
                    {" "}
                    <Link to="/" style={{ cursor: "pointer", color: "black" }}>
                      Solar Panel{" "}
                    </Link>
                  </b>
                  are designed to capture sunlight from both the front and rear
                  sides of the panel, increasing energy production efficiency.
                </li>
                <li>
                  <b>Increased Energy Generation:</b> Bifacial technology
                  combined with a transparent backsheet results in enhanced
                  energy generation.
                </li>
                <li>
                  <b>Durability and Longevity:</b> The transparent backsheet is
                  designed to withstand harsh weather conditions, UV exposure,
                  and temperature fluctuations, ensuring a long lifespan.
                </li>
                <li>
                  <b>Environmental Benefits: </b>The transparent backsheet
                  technology aligns with sustainable and environmentally
                  friendly energy solutions. and solar modules contribute to
                  greater energy efficiency and reduced
                  greenhouse gas emissions.
                </li>
              </p>
            </div>
          </div>
          <Turnel />

          <div className="big-child-container">
            <div className="col-md-10 big-header-section">
              <div className="row" style={{ margin: "10px" }}>
                <div className="child-container child-container-2 m-0">
                  <div className="col-md-10 header-section mb-0">
                    <h2
                      data-aos-duration="800"
                      data-aos="fade-right"
                      data-aos-once="true"
                    >
                      Jinko Bifacial Solar Panels Benefits
                    </h2>
                    <p
                      data-aos-duration="800"
                      data-aos="fade-left"
                      data-aos-once="true"
                    >
                      <li>
                        Jinko Bifacial Solar Modules Higher Power Warranty - 30
                        years power warranty
                      </li>
                      <li>Lesser degradation - Only 0.45% annually</li>
                      <li>Increased energy yield - Up to 30%</li>
                      <li>
                        Temperature coefficient of Voc - Better in Bifacial at
                        0.28
                      </li>
                      <li>
                        Lower hot-spot risks - Bifacial modules have lower risk
                        of hot spots
                      </li>
                      <li>
                        Higher return on investment - Jinko Bifacial Solar
                        Panels have sleek and modern look
                      </li>
                      <li>
                        Jinko Bifacial Solar Modules have Better performance in
                        low light - More efficient in low light conditions
                      </li>
                    </p>

                    <h2
                      data-aos-duration="800"
                      data-aos="fade-right"
                      data-aos-once="true"
                      className="my-5"
                    >
                      Structure of Solar Cell
                    </h2>
                    <p
                      data-aos-duration="800"
                      data-aos="fade-left"
                      data-aos-once="true"
                    >
                      Jinko Solar is the world's leading{" "}
                      <b>
                        {" "}
                        <Link
                          to="../solar-product"
                          style={{ cursor: "pointer", color: "black" }}
                        >
                          Solar Module{" "}
                        </Link>
                      </b>
                      manufacturer. It produces solar cells that are a
                      fundamental component of solar panels.
                      <li className="mt-3">
                        <b>Silicon Wafer </b>: Jinko Solar primarily uses
                        crystalline silicon wafers, which can be monocrystalline
                        or multicrystalline.
                      </li>
                      <li>
                        <b>Emitter Layer</b>: This layer is heavily doped with
                        specific materials to create an excess of free
                        electrons, making it conducive to electrical
                        conductivity.
                      </li>
                      <li>
                        <b>Front Surface </b>: The front surface of the silicon
                        wafer is treated to optimize light absorption.
                      </li>
                      <li>
                        <b>Passivation Layer</b>: A thin passivation layer is
                        applied to the rear surface to reduce the recombination
                        of electrons and holes, further enhancing the
                        efficiency of the cell
                      </li>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="child-container-second">
            <div className="col-md-10 child-container-secondary">
              <div className="row" style={{ margin: "10px" }}>
                <div className="col-md-6 primary-container">
                  <img
                    src={fgmr}
                    className="module-images img-fluid "
                    title="bifacial-solar-panel"
                    alt="bifacial-solar-panel"
                    data-aos-duration="800"
                    data-aos="zoom-in-right"
                    data-aos-once="true"
                  />
                </div>

                <div className="col-md-6 secondary-container">
                  <img
                    src={fgml}
                    alt="Mono-bifacial-solar-panel"
                    title="Mono-bifacial-solar-panel"
                    className="module-images img-fluid "
                    data-aos-duration="800"
                    data-aos="zoom-in-left"
                    data-aos-once="true"
                  />
                </div>
                <div className="col-md-12 header-section d-flex justify-content-conter m-0">
                  <div className="text-center-container col-md-10 m-auto">
                    <h2
                      data-aos-duration="800"
                      data-aos="fade-right"
                      data-aos-once="true"
                      className="mt-5"
                    >
                      Jinko Tiger Neo N-Type TOPCon - A Notch Above
                    </h2>
                    <p
                      className="mb-0"
                      data-aos-duration="800"
                      data-aos="fade-left"
                      data-aos-once="true"
                    >
                      The Jinko Tiger Neo N-Type TOPCon is a Modern solar panel
                      technology that represents a significant advancement in
                      the world of photovoltaics. This innovative solar module,
                      offered by Jinko Solar, in terms of efficiency,
                      performance, and sustainability.
                      <br />
                      <br />
                      If you are looking for a Jinko Solar Panel Distributor in India or Buy Jinko solar panels in India, OneKlick is ideal for you. We also offer N-Type Solar Panels and N-Type Solar Modules to cater to your diverse solar energy needs.

At OneKlick, our experienced team is dedicated to assisting you in finding the best solar panels that align with your budget and project requirements. We take pride in being your trusted partner in harnessing the power of solar energy for a sustainable and eco-friendly future.
                    </p>
                  </div>
                </div>

                <div className="col-md-12" style={{ margin: "20px" }}>
                  <div className="row">
                    <div className="col-md-4  header-content-first">
                      <div className="header-content-first-section">
                        <h3>
                          <i class="fa-solid fa-regular fa-heart"></i> Daily
                          Production
                        </h3>
                        <p>
                          You can get almost 0.5 hours more production daily
                          with N-type solar panels than P-type solar panels.
                        </p>
                      </div>
                      <div className="header-content-second-section">
                        <h3>
                          <i class="fa fa-lock"></i>Temperature Coefficient
                        </h3>
                        <p>
                          {" "}
                          Jinko Solar Temperature coefficients of -0.29% per
                          degree Celsius
                        </p>
                      </div>
                    </div>

                    <div className="col-md-4  header-content-first">
                      <div className="header-content-first-section">
                        <h3>
                          <i class="fa fa-link"></i> Price Range
                        </h3>
                        <p>
                          N-type solar modules are more expensive to produce
                          than P-type modules.
                        </p>
                      </div>
                      <div className="header-content-second-section">
                        <h3>
                          <i class="fa fa-message"></i> Degradation
                        </h3>
                        <p>
                          Degradation is less than 1% in first year and 0.4% in
                          consecutive year.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4  header-content-first">
                      <div className="header-content-first-section">
                        <h3>
                          <i class="fa-solid fa-hand-pointer mr-2"></i>
                          Efficiency
                        </h3>
                        <p>
                          Jinko solar Module Efficiency up-to 23.23% dule
                          Efficiency up-to 23.23%.
                        </p>
                      </div>
                      <div className="header-content-second-section">
                        <h3>
                          <i class="fa-sharp fa-solid fa-eye-slash"></i>{" "}
                          Performance Warranty
                        </h3>
                        <p>
                          With 87.4% performance warranty for 30 years, get more
                          production year after year.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="header-contaier-big-container col-md-12">
                  <div className="col-md-9 header-contaier-big-container-child">
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Parallax />
          <div className="col-md-12 testimonial-section m-0">
            <div className="text-aling-center d-flex justify-content-center">
              <div className="testimonial-main-container col-md-8"></div>
            </div>
            <div className="testi-content-container col-md-12">
              <div className="client-testi col-md-2 ">
                <p>Client Testimonial</p>
              </div>
            </div>
            <div className="testi-header-section">
              <h4>
                Uplifting Spirits, Inspiring Stories: <br /> Heartwarming
                Testimonials from Our Valued Clients{" "}
              </h4>
            </div>

            <Splide
              aria-label="My Favorite Images"
              options={{ perPage: perPage, gap: "50px" }}
              id="splideCon"
            >
              <SplideSlide>
                <div className="center-containers">
                  <div
                    className="testimonial-first-container col-md-10"
                    id="firstcontainer"
                  >
                    <div className="row">
                      <div className="col-md-7 starter-details">
                        <h5>Harsh Jondle</h5>
                        <p style={{ fontSize: "19px" }}>JD Enerbiz Pvt. Ltd</p>
                        <p>
                          Oneklick is solar panels distributor company in india.
                          We Highly satisfied! Excellent solar panels and great
                          service. Oneklick is best distributor! We are Highly
                          recommend for solar modules in India.
                        </p>
                        <br />
                        <div className="star-container">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-regular fa-star"></i>
                        </div>
                      </div>
                      <div className="col-md-5 remake m-auto">
                        <img
                          src={test_img}
                          alt="client-1"
                          title="client-1"
                          id="firstimg"
                          width={"93%"}
                          height={"100%"}
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div
                  className="testimonial-first-container col-md-10"
                  id="firstcontainer"
                >
                  <div className="row">
                    <div className="col-md-7 starter-details">
                      <h5>Harvinder Singh</h5>
                      <p style={{ fontSize: "19px" }}>
                        Solidus Techno Power Pvt.
                      </p>
                      <p>
                        Oneklick is Reliable solar service, its have provide
                        top-quality panels, and excellent prices. Highly
                        recommend this solar panel distributor!
                      </p>{" "}
                      <br /> <br />
                      <div className="star-container">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                      </div>
                    </div>
                    <div className="col-md-5 remake m-auto">
                      <img
                        src={test_img2}
                        alt="client-2"
                        title="client-2"
                        id="firstimg"
                        width={"93%"}
                        height={"100%"}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="center-containers">
                  <div
                    className="testimonial-first-container col-md-10"
                    id="firstcontainer"
                  >
                    <div className="row">
                      <div className="col-md-7 starter-details">
                        <h5>Simran Singh</h5>
                        <p style={{ fontSize: "19px" }}>
                          Bliss Solar Power Pvt. Ltd.
                        </p>
                        <p>
                          Exceptional solar module distributor! OneKlick offer a
                          wide range of high-quality Solar modules, efficient
                          service, and competitive Solar panel prices. A
                          reliable partner for all solar needs.
                          Highly recommended!
                        </p>
                        <div className="star-container">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-regular fa-star"></i>
                        </div>
                      </div>
                      <div className="col-md-5 remake m-auto">
                        <img
                          src={test_img3}
                          alt="client-3"
                          title="client-3"
                          id="firstimg"
                          width={"93%"}
                          height={"100%"}
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div
                  className="testimonial-first-container col-md-10"
                  id="firstcontainer"
                >
                  <div className="row">
                    <div className="col-md-7 starter-details">
                      <h5>Sharad Dutta Acharya</h5>
                      <p style={{ fontSize: "19px" }}>
                        Bull Power Energy Pvt. Ltd.
                      </p>
                      <p>
                        Best Solar Panels distributor in India! Reliable supply
                        of high-quality solar modules. Efficient service, prompt
                        delivery, and affordable prices. A trusted partner for
                        all solar needs. <br /> <br />
                      </p>
                      <div className="star-container">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                      </div>
                    </div>
                    <div className="col-md-5 remake m-auto">
                      <img
                        src={test_img4}
                        alt="client-4"
                        title="client-4"
                        id="firstimg"
                        width={"93%"}
                        height={"100%"}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
      </section>
    </>
  );
}
