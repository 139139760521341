import React, { useEffect, useState } from "react";
import "../App.css?v=1.1";
import Navbar from "./Navbar";
import Video from "./Video1";
import Section2 from "./section2";
import Warehouse from "./Warehouse1";
import Products from "./productss";
import Work_process from "./work_process";
import Partner from "./Partner";
import Contact from "./contact";
import CustomModal from "./CustomModal";
import Difference from "./difference";
import Footer from "./footer";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const WarehousePage = () => <h1>Welcome to the Home Page</h1>;

function App() {
  // const [modalIsOpen, setModalIsOpen] = useState(false);
 // modalIsopen a value, setmodelisopen is a function
    // const timehandle =()=>{
    //   const showtime = setTimeout(() => {
    //     setModalIsOpen(true)
    //   }, 3000);
    //   return ()=> clearTimeout(showtime)
    // }
    // useEffect(()=>{
    //   timehandle()
    // },[]) // showing on first render


    // const closeModal = () => {
    //     setModalIsOpen(false);
    // };
  
  return (
    <>
    <HelmetProvider>
      <Helmet>
        <title>Solar Panel Distributor in India | Buy Solar Module At Best Prices</title>
        <meta name="description" content="Oneklick is solar panel distributor in India. Buy solar modules, PV modules, solar power systems, and Solar solutions at best prices for homes, offices, and commercial spaces."/>
        <meta name="keywords" content="Solar Panels Distributor in India, Solar module Distributor in india, Solar panel prices, Solar Modules, Solar PV modules solutions, Solar Panel supplier in india, solar panel subsidy, Jinko solar panels, Solex solar panels, Axitec solar panels, Novasys solar panels"/>
        <link rel="canonical" href="https://oneklick.in/" /> 
        <meta name="twitter:title" content="Solar Panel Distributor in India | Buy Solar Module At Best Prices" />
        <meta name="twitter:description" content="Oneklick is solar panel distributor in India. Buy solar modules, PV modules, solar power systems, and Solar solutions at best prices for homes, offices, and commercial spaces." />
        <meta property="og:title" content="Solar Panel Distributor &amp; in India | Buy Solar Module At Best Prices"/>
        <meta property="og:description" content="Oneklick is solar panel distributor in India. Buy solar modules, PV modules, solar power systems, and Solar solutions at best prices for homes, offices, and commercial spaces."/>
        <meta property="og:url" content="https://oneklick.in/" />
      </Helmet>
      </HelmetProvider>
      {/* <CustomModal isOpen={modalIsOpen} closeModal={closeModal} /> */}
      <Navbar />
      <Video />
      <Section2 />
      <Products />
      <Warehouse component={WarehousePage} />
      <Partner />
      <Work_process />
      <Difference />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
