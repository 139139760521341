import React from "react";
import "./faq.css";

const Faq = ({ detail }) => {
  return (
    <>
      <div className="faq-title mt-3"
             data-aos-duration="800"
             data-aos="zoom-out"
             data-aos-once="true">
        <h4 className="faptitle text-center"><span>frequently asked question's</span></h4>
      </div>
      <div className="d-flex justify-content-center align-items-center faq-context m-0 py-0"
             data-aos-duration="800"
             data-aos="zoom-out"
             data-aos-once="true">
        <div
          class="accordion accordion-flush col-md-9"
          id="accordionFlushExample"
        >
          {detail.map((item, index) => {
            return (
              <div class="accordion-item bg-transparent" key={index}>
                <h4
                  class="accordion-header border-none"
                  id={`flush-headingOne ${index}`}
                >
                  <button
                    class="accordion-button acc-btn collapsed bg-transparent"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${index}`}
              
                    aria-controls={`flush-collapse${index}`}
                  >
                    {item.question}
                  </button>
                </h4>
                <div
                  id={`flush-collapse${index}`}
                  class="accordion-collapse collapse"
                  aria-labelledby={`flush-heading${index}`}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body acc-bdy">{item.answer}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Faq;
