import React from 'react'
import "../WarehousePage.css?v=1.1"
import Warehouse_pic from "../images/oneklick-warehouse-2.webp"
import Navbar from "./Navbar"
import Footer from "./footer"
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function WarehousePage() {
    const openMap=()=>{
        return(
      <div>
        <iframe src="https://goo.gl/maps/x5EP8JZmTwmfr1yC8" frameborder="0"></iframe>
      </div>
        )
    }

  return (
    
    <div>
<Navbar/>
<HelmetProvider>
<Helmet>
  <title>Know Oneklick Techno Renewable Warehouses In India</title>
  <meta name="description" content=" OneKlick warehouses are located in Ludhiana, Alwar, Jaipur, Ahmedabad, Bhiwandi, Hyderabad, Chennai and across India. Get solar modules & solar EPC services from Anywhere.
"/>
<meta name="keywords" content=" solar panel distributor in india, solar panel distributor in jaipur, solar panel distributor in Alwar, solar panel distributor in Ahmedabad, solar panel distributor in Ludhiana, solar panel distributor in Bhiwandi, solar panel distributor in Hyderabad, solar panel distributor in Chennai
"/>
<link rel="canonical" href="https://oneklick.in/warehouse" />
<meta name="twitter:title" content="Know Oneklick Techno Renewable Warehouses In India" />
<meta name="twitter:description" content=" OneKlick warehouses are located in Ludhiana, Alwar, Jaipur, Ahmedabad, Bhiwandi, Hyderabad, Chennai and across India. Get solar modules & solar EPC services from Anywhere.
" />
<meta name="twitter:url" content="https://oneklick.in/"/>
<meta property="og:title" content="Know Oneklick Techno Renewable Warehouses In India"/>
<meta property="og:description" content="OneKlick warehouses are located in Ludhiana, Alwar, Jaipur, Ahmedabad, Bhiwandi, Hyderabad, Chennai and across India. Get solar modules & solar EPC services from Anywhere.
"/>
<meta property="og:url" content="https://oneklick.in/warehouse" />
    </Helmet>
    </HelmetProvider>
      <div class="col-md-12 warehouse-main-container">
      <div className="row row1">
                <div className="col-md-6 firstCol">
                    <h1 className="aboutHeading">Warehouse</h1>
                    <div className="button1">
                        <a href="">Home<i class="fa-solid fa-angle-right"></i><span>Warehouse</span></a>
                    </div>
                </div>
                <div className="col-md-6 secondCol">
                    <img className="image" src={Warehouse_pic} alt="oneklick-warehouse-img" title='oneklick-warehouse-img'/>
                </div>
            </div>


        <div class="my-cards">
            <div class="row cards-container" style={{margin:0}}>
            
                <div class="col-md-3 first-context  alwar-container" id="alwar" >
                    <div class="header-section" style={{textAlign:'start'}}>
                        <i class="fa-sharp fa-solid fa-location-dot fa-2x "></i>
                        <a href="https://goo.gl/maps/x5EP8JZmTwmfr1yC8"><h3>Alwar </h3></a>
                    </div>
                    <div class="person-name">
                        <i class="fa-solid fa-user "></i>
                        <p>Ms. Rashi Arora</p>
                    </div>
                    <div class="person-name">
                        <i class="fa-solid fa-phone "></i>
                        <p>+919511568993</p>
                    </div>
                </div>
                
                <div class="col-md-3  second-context jaipur-container">
                    <div class="header-section" style={{textAlign:'start'}}>
                        <i class="fa-sharp fa-solid fa-location-dot fa-2x "></i>
                        <h3>Jaipur</h3>
                    </div>
                    <div class="person-name">
                        <i class="fa-solid fa-user "></i>
                        <p>Ms. Rashi Arora</p>
                    </div>
                    <div class="person-name">
                        <i class="fa-solid fa-phone "></i>
                        <p>+919511568993</p>
                    </div>
                </div>
                <div class="col-md-3 third-context ludhiana-container">
                    <div class="header-section" style={{textAlign:'start'}}>
                        <i class="fa-sharp fa-solid fa-location-dot fa-2x "></i>
                        <h3>Ludhiana</h3>
                    </div>
                    <div class="person-name">
                        <i class="fa-solid fa-user "></i>
                        <p>Ms. Monisha Aggarwal</p>
                    </div>
                    <div class="person-name">
                        <i class="fa-solid fa-phone "></i>
                        <p>+919358589009</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-cards mt-5">
            <div class="row cards-container" style={{margin:0}}>
                <div class="col-md-3 first-context ahmedabad-container ">
                    <div class="header-section" style={{textAlign:'start'}}>
                        <i class="fa-sharp fa-solid fa-location-dot fa-2x "></i>
                        <h3>Ahemdabad</h3>
                    </div>
                  
                    <div class="person-name">
                        <i class="fa-solid fa-user "></i>
                        <p>Mr. Goonwat Shekhawat</p>
                    </div>
                    <div class="person-name">
                        <i class="fa-solid fa-phone "></i>
                        <p>+919251697393</p>
                    </div>
                </div>
                <div class="col-md-3  second-context bhiwandi-container">
                    <div class="header-section" style={{textAlign:'start'}}>
                        <i class="fa-sharp fa-solid fa-location-dot fa-2x "></i>
                        <h3>Bhiwandi</h3>
                    </div>
                    <div class="person-name">
                        <i class="fa-solid fa-user "></i>
                        <p>Ms. Monisha Aggarwal</p>
                    </div>
                    <div class="person-name">
                        <i class="fa-solid fa-phone "></i>
                        <p>+919358589009</p>
                    </div>
                </div>
                <div class="col-md-3 third-context hyderabad-container">
                    <div class="header-section" style={{textAlign:'start'}}>
                        <i class="fa-sharp fa-solid fa-location-dot fa-2x "></i>
                        <h3>Hyderabad</h3>
                    </div>
                    <div class="person-name">
                        <i class="fa-solid fa-user "></i>
                        <p>Ms. Ranu Khandelwal</p>
                    </div>
                    <div class="person-name">
                        <i class="fa-solid fa-phone "></i>
                        <p>+919358589005</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-cards mt-5">
            <div class="row cards-container chennai-container" style={{margin:0}}>
                <div class="col-md-3 first-context ">
                    <div class="header-section" style={{textAlign:'start'}}>
                        <i class="fa-sharp fa-solid fa-location-dot fa-2x "></i>
                        <h3>Chennai</h3>
                    </div>
                    <div class="person-name">
                        <i class="fa-solid fa-user "></i>
                        <p>Mr. Nitin Sain</p>
                    </div>
                    <div class="person-name">
                        <i class="fa-solid fa-phone "></i>
                        <p>+918302884895</p>
                    </div>
                </div>
                <div class="col-md-3 first-context ">
                    <div class="header-section" style={{textAlign:'start'}}>
                        <i class="fa-sharp fa-solid fa-location-dot fa-2x "></i>
                        <h3 style={{color:'lightgreen'}}>Nagpur</h3>
                    </div>
                    <div class="person-name">
                        <i class="fa-solid fa-user "></i>
                        <p>Mr. Goonwat Shekhawat</p>
                    </div>
                    <div class="person-name">
                        <i class="fa-solid fa-phone "></i>
                        <p>+919251697393</p>
                    </div>
                </div>
            </div>
            
        </div>
     
        
        
        
    </div>
    <Footer/>
    </div>
   
  )
}
