import React from 'react'
import Navbar from "../Navbar"
import Footer from "../footer"
import "./mainsolplanet.css"
import { Link } from 'react-router-dom'
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useState,useEffect } from 'react'
import solproductone from "../../images/1-_250kw_central_standalone.webp"
import solproductsecond from "../../images/3-utility_3.webp"
import solarproductthird from "../../images/7-UTILITY_3-10.webp"
import solarproductfour from "../../images/8-UTILITY60KW.webp"
import solarproductfive from "../../images/9-AISWEI.webp"
import Faq from '../Faq'
import Vision from "./visionsetion"



import tigerneobi  from "../../images/tiger2 - 465 Ptype Bifacial.png"
import tigerneobipdf  from "../../images/JKM565-585N-72HL4-BDV-F4-EN (IEC 2005).pdf"

import tigerneobi2  from "../../images/tiger2 - 465 Ptype Bifacial.png"
import tigerneobipdf2  from "../../images/JKM600-620N-78HL4-BDV-F4-EN (IEC 2005).pdf"
import img1 from "../../images/aswlt-g3.png"
import pdf1 from "../../images/aswlt-g3.png"
import img2 from "../../images/three-phase-hybrid.png"
import pdf2 from "../../images/three-phase-hybrid.pdf"
import img3 from "../../images/single-phase-hybrid.png"
import pdf3 from "../../images/single-phase-hybrid.pdf"
import img4 from "../../images/sqlevpower.png"
import pdf4 from "../../images/sqlevpower.pdf"
import img5 from "../../images/asw3000-s.png"
import pdf5 from "../../images/asw3000-s.pdf"
import img6 from "../../images/aswlt-g2.png"
import pdf6 from "../../images/aswlt-g2.pdf"


const Mainsolplanet = () => {
    
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [product, setProduct] = useState('');
const[message,setMessage] =useState('')
  const [validation , setvalidation] = useState(false)
console.log(message)

  const sendEmail = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
        if(form.checkValidity() === false){
          e.stopPropagation();
        }else{
          if(name === '' && email === '' && contact === '' &&  product == '')  {
            Swal.fire({
              icon: 'error',
              title: 'Invalid Name',
              text: 'Please Fill The All Details',
            });
            return;
          }
       
        else if 
        (name === '') {
          Swal.fire({
            icon: 'error',
            // title: 'Invalid Name',
            text: 'Please Enter Your Name',
          });
          return;
        }
        else if
          (email === '') {
            Swal.fire({
              icon: 'error',
              // title: 'Fill The Ema',
              text: 'Please Enter Your Email',
            });
            return;
          }
        else if (contact.length < 10) {
          Swal.fire({
            icon: 'error',
            // title: 'Invalid Contact Number',
            text: 'Please enter a valid contact number with at least 10 digits',
          });
          return;
        }
        else if (product === '') {
          Swal.fire({
            icon: 'error',
            // title: 'Invalid Contact Number',
            text: 'Select The Module ',
          });
          return;
        }
      }
    try {
      $.ajax({
        url: 'https://appj.scaleedge.in/register',
        type: 'POST',
        data: { subject:'Enquiry For Module',message:message, name : name, email: email, contact : contact,product : product },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (response) {
            console.log('Email sent successfully');
            Swal.fire(
              'Good job!',
              'Mail Send Successfully',
              'success'
            );
            console.log(response);
            setName('');
            setEmail('');
            setContact('');
            setProduct('');
           
        },
        error: function (err) {
          console.log(err);
            //alert(11);
            // alert(err.responseText);
        }
      });
      setvalidation(true);
    }
    catch(error){
      console.log(error);
    }
  }

  const faqdeatil = [
    {
      question: " Q1. What is the Price of Solplanet Inverter in India?",
      answer:
        "The price of a Sol Plane inverter can vary widely depending on several factors, including the type of inverters, its capacity, and additional features. ",
    },
    {
      question: "Q2. What Inverter Is Right for My Home?",
      answer:
        "OneKlick helps you select the right inverter for your solar power system. This can have a significant impact on system performance, energy production, and overall efficiency. The inverter depends on your home and energy needs.",
    },
    {
      question: "Q3. What is Solplanet Inverter Efficiency? ",
      answer:
        "Solplanet inverter efficiency is an important factor to consider when evaluating and designing a solar power system. Inverter efficiency is usually expressed as a percentage and shows how effectively the inverter performs its primary function.",
    },
    {
      question: "Q4. Why do we need Solplanet Solar inverters? ",
      answer:
        "Solar panels generate DC electricity, but home appliances and the grid operate on AC electricity. A solar inverter is necessary to convert DC electricity into usable AC electricity.",
    },
    {
      question: "Q5. What is a Hybrid Solar Inverter?",
      answer:
        "Hybrid solar inverter combines the functions of a standard solar inverter with a battery inverter/charger. It stores excess solar energy in batteries for use during periods of low sunshine or during power cuts.",
    },
    {
      question: "Q6. What is the difference between string inverters and microinverters?",
      answer:
        "String inverters are usually installed in a central location and connect multiple solar panels in a series. But Microinverters are installed on each individual panel and convert DC to AC at the panel level. It provides better performance in cases of shading or panel-level mismatch.",
    },
    {
      question: "Q7 . Can I connect my Solplanet inverter to the grid?",
      answer:
        "Yes, grid-tied inverters allow you to connect your solar system to the electrical grid.",
    },
  ];

  
  const differenceData = [
    {
      sheets:pdf1,
      imgSrc: img1,
      title: "ASW LT-G3",
      point1: "20A inout current, ideal for bifacial and large area PV modules",
      point2: "3 MPPT´s for flexible PV array design",
      point3: " WMPPT´s for flexible PV array design",
      point4: " ShadeSol shadow management",
    },
    {

      sheets:pdf2,
      imgSrc: img2,
      title: " Three Phase Hybrid",
      point1: "User friendly app interface",
      point2: "Online monitoring via Wi-Fi and  Solplanet apps",
      point3: "Support unbalanced output",
      point4:'8/10/12kW 3-Phase Hybrid Inverter'

    },
    {
      sheets:pdf3,
      imgSrc: img3,
      title: "Single Phase Hybrid",
      point1: "User friendly app interface",
      point2: "Online monitoring via Wi-Fi and Solplanet apps",
      point3: " Easy to connect - battery and smart  meter interfaces",
      point4: " ShadeSol shadow management",
    },
    {
      sheets:pdf4,
      imgSrc: img4,
      title: "SOL EVPOWER",
      point1: "Scheduled EV charging",
      point2: "Adjustable charging power",
      point3: " RFID for user authentication",
      point4: " LED status indicators",
    },
    {
      sheets:pdf5,
      imgSrc: img5,
      title: "Single-Phase String Inverter",
      point1: "Supports up to 150% DC/AC ratio",
      point2: "Small and light thanks to compact design",
      point3: "Fast insatlltion with no lid opening necessary",
      point4: "IP65 for outdoor use",
    },
    {
      sheets:pdf6,
      imgSrc: img6,
      title: "ASW LT-G2 Pro",
      point1: "User friendly app interface",
      point2: "Max.20 A input current, ideal for bifacial and large area PV modules",
      point3: "Wide MPP voltage range 150V-1000V",
      point4: "ShadeSol shadow management",
    },
  ];

  return (
    <div>
     <HelmetProvider>
        <Helmet>
          <title>
          Solplanet Inverter Authorised Distributor in India | Oneklick
          </title>
          <meta
            name="description"
            content="Oneklick is authorised distributor of solplanet inverters. We offers top inverters In India inculding string inverters, for residential and commercial solar power systems."
          />
          <meta
            name="keywords"
            content=" Solplanet Distributor, Solplanet Inverter, Solplanet Inverter in India, Inverter Distributor in India, Best Inverter in India, Top 5 Inverters, Best String Inverter, String Inverter with Shadow Management, Best Inverter for 10 Years Warranty"
          />
          <link rel="canonical" href="https://oneklick.in/solplanet" />
          <meta
            name="twitter:title"
            content="Solplanet Inverter Authorised Distributor in India | Oneklick"
          />
          <meta
            name="twitter:description"
            content="Oneklick is authorised distributor of solplanet inverters. We offers top inverters In India inculding string inverters, for residential and commercial solar power systems."
          />
          <meta
            property="og:title"
            content="Solplanet Inverter Authorised Dis tributor in India | Oneklick"
          />
          <meta
            property="og:description"
            content="Oneklick is authorised distributor of solplanet inverters. We offers top inverters In India inculding string inverters, for residential and commercial solar power systems."
          />
          <meta property="og:url" content="https://oneklick.in/solplanet" />
          <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Question",
            "name": "What is the Price of Solplanet Inverter in India?",
            "text": "The price of a Sol Plane inverter can vary widely depending on several factors, including the type of inverters, its capacity, and additional features. "
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Question",
            "name": "What Inverter Is Right for My Home?",
            "text": "OneKlick helps you select the right inverter for your solar power system. This can have a significant impact on system performance, energy production, and overall efficiency. The inverter depends on your home and energy needs."
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Question",
            "name": "What is Solplanet Inverter Efficiency?",
            "text": "Solplanet inverter efficiency is an important factor to consider when evaluating and designing a solar power system. Inverter efficiency is usually expressed as a percentage and shows how effectively the inverter performs its primary function."
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Question",
            "name": "Why do we need Solplanet Solar inverters?",
            "text": "Solar panels generate DC electricity, but home appliances and the grid operate on AC electricity. A solar inverter is necessary to convert DC electricity into usable AC electricity."
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Question",
            "name": "What is a Hybrid Solar Inverter?",
            "text": "Hybrid solar inverter combines the functions of a standard solar inverter with a battery inverter/charger. It stores excess solar energy in batteries for use during periods of low sunshine or during power cuts."
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Question",
            "name": "What is the difference between string inverters and microinverters?",
            "text": "String inverters are usually installed in a central location and connect multiple solar panels in a series. But Microinverters are installed on each individual panel and convert DC to AC at the panel level. It provides better performance in cases of shading or panel-level mismatch."
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Question",
            "name": "Can I connect my Solplanet inverter to the grid?",
            "text": "Yes, grid-tied inverters allow you to connect your solar system to the electrical grid"
          })}
        </script>
        </Helmet>
      </HelmetProvider>
      <Navbar/>
        <div className="col-md-12 main-solplanet-container">
          <div className="row product-row">
            <div className="col-md-8 product-left-container">
              {/* <h1 className="product-title">Authorised Distributor of</h1> */}
              <div className="row">
                <div className="col-md-12">
                <div className="title-header" id="title-head">
                {/* <h1 className="fs-1">JINKO SOLAR</h1> */}
                <h1 className="fs-1">SOLPLANET INVETER</h1>
                
              </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 product-right-container">
              <div className="product-right-content">
                <form className="needs-validation form-container" noValidate>
                  <p className="head-title text-center mb-3 p-0" style={{fontSize:'30px',color:'white'}}> <p className="fw-5 fs-4 fw-light p-0 mb-0 lh-sm">Contact Form</p></p>
                  <div className="form-group me-class">
                    <input
                      type="text"
                      
                      className="form-control product-form-control"
                      id="validationCustom01"
                      aria-describedby="emailHelp"
                      placeholder="Name"
                      autoComplete="off"
                      required
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      
                    />
                  </div>
                  <div className="form-group product-form-group  me-class">
                    <input
                      type="email"
                      className="form-control product-form-control"
                      id="validationCustom03"
                      placeholder="Email"
                      required
                      name="email"
                      value={email}
                    
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group product-form-group  me-class">
                    <input
                      type="number"
                      className="form-control product-form-control"
                      id="validationCustom05"
                      placeholder="Number"
                      required
                      name="number"
                      value={contact}      
                      onChange={(e) => setContact(e.target.value)}
                    />
                  </div>
                  <div className="form-group product-form-group  me-class">
                    <input
                      type="text"
                      className="form-control product-form-control"
                      id="validationCustom04"
                      placeholder="Requirement"
                      required
                      name="module"
                      value={product}      
                      onChange={(e) => setProduct(e.target.value)}
                    />
                  </div>
                  {/* <div className="form-group w-75 products-form  me-class" >
                    <select
                      className="form-control product-form-control"
                      id="validationCustom04"
                      placeholder="Select Module"
                      required
                      style={{borderRadius:'10px'}}
                      name="module"
                      value={product}      
                      onChange={(e) => setProduct(e.target.value)}>
                      <option value="" style={{color:'white'}}>Select Module</option>
                      <option style={{color:'white'}}>Jinko - 550Wp</option>
                      <option style={{color:'white'}}>Jinko - 470Wp</option>
                      <option style={{color:'white'}}>Axitec - 550Wp</option>
                      <option style={{color:'white'}}>Axitec - DCR - 335WP</option>
                      <option style={{color:'white'}}>Axitec - Non DCR - 335WP</option>
                      <option style={{color:'white'}}>Novasys - 540Wp</option>
                      <option style={{color:'white'}}>Novasys - DCR - 335Wp</option>
                      <option style={{color:'white'}}>Novasys - Non DCR - 335Wp</option>
                    </select>
                  </div> */}
                  <div className="col-md-12 message-con  me-class ">
                    <input
                      type="text"
                      class="form-control  "
                      name="message"
                      value={message}
                      placeholder="MESSAGE"
                    />
                  </div>
                  {/* <input type="text" readOnly className="col-md-12 rounded-3 readvalue" style={{fontSize:'15px'}} value={message} /> */}
                  <button type="submit" onClick={sendEmail} className="btn btn-dark btn-md mt-3 me-class product-btn submitbtns" style={{borderRadius:'10px'}}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 header-section 
         flex-column  d-flex justify-content-center align-items-center "
            style={{ marginTop: "50px" }}
          >
            <h2
              className="col-md-9  text-center "
              data-aos-duration="800"
              data-aos="fade-right"
              data-aos-once="true"
              
            >
               Solplanet Inverters
            </h2>
            <p className="col-md-9 text-start p-4 "
                   data-aos-duration="800"
                   data-aos="fade-left"
                   data-aos-once="true">
            Oneklick is an authorized distributor of Solplanet inverters in India, Solplanet is at the forefront of solar inverter technology. Solplanet takes the lead in this industry with its innovative solar inverters. Its solar inverters are designed for extreme performance, reliability, and ease of use. Oneklick provides a wide range of top-quality solar inverters in India, making the utilization of solar power more accessible than ever before.
            </p>

                {/* Card Section */}
                <div className="col-md-9 header-section mb-0 planet-div">
              <h2
                data-aos-duration="800"
                data-aos="fade-right"
                data-aos-once="true"
                className='px-4'
              >
              Top Solplanet Inverter In India
              </h2> 
              <p>The range of Solplanet inverters includes the ASW LT- G3 series, Three Phase Hybrid Inverter, Single Phase Hybrid Inverter, SOL Evpower Series, Single Phase String Inverter, and String Inverter with shadow management. These are user-friendly designs for advanced models equipped with smart features. Solplanet inverters are used for residential or commercial and it has your solar needs covered. Solplanet solar inverters are not just energy-efficient, they're the foundation of a cleaner, greener future.</p>
            </div>
                <div className="child-container">
            
          </div>
             <div className="differences ">
            <div className="section-content p-1">
              <div
                className="row col-md-9 "
                style={{ justifyContent: "space-around", margin: "auto" }}
              >
                {differenceData.map((item, index) => (
                  <div
                    className="col-md-4  "
                    data-aos="flip-up"
                    data-aos-once="true"
                    key={index}
                  >
                    <div className="product-imgs product-hover bg-light">
                        <div className="text-center">
                      <img
                        src={item.imgSrc}
                        height={"250px"}
                        width={"100px"}
                        className="img-icon"
                        alt={item.alt}
                        title={item.title}
                      />
                      </div>
                      <div className="overlay">
                        <div className="text"></div>
                      </div>
                      <h3 className="">{item.title}</h3>
                    </div>
                    <div className="flex-column d-flex">
                    <p
                      className="differnces flex-column"
                      style={{
                        float: "left",
                        display: "flex",
                        alignItems: "flex-start",
                        minHeight:'160px'
                      }}
                    >
                      <li>{item.point1}</li>
                      <li>{item.point2}</li>
                      <li>{item.point3}</li>
                      <li>{item.point4}</li>
                    </p>
                    <div className="mb-5 mt-2 d-flex justify-content-center align-items-center">
                    <a className="btn text-light downloadsheet" onClick={()=>window.open(item.sheets)}>Download Datasheet</a>
                    </div>
                    </div>
                  </div>
                  
                ))}
 
              </div>
            </div>
          </div>
          
              
            
            <Vision/>
          </div>

         
          <Faq detail ={faqdeatil}/>
         
        <Footer/>
    </div>
  )
}

export default Mainsolplanet
